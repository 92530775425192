import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _, { cloneDeep } from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';
import ReusableCheckboxDropdown from '../Common/CheckBoxDropdowComponent';
import moment from 'moment';
const OperatorPerformanceBoard = ({ handleDownloadTableToExcel, equips, equipGroups, fromDateLimit, storeDataInLocalStorage }) => {
    const [productionData, setProductionData] = useState([])
    const [inputData, setInputData] = useState({})
    const [errors, setErrors] = useState({});
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
    const [index, setIndex] = useState(0);
    const [querySuccess, setQuerySuccess] = useState(false);
    const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })
    const [componentdata, setcomponentList] = useState([]);
    const [operationdata, setoperationList] = useState([]);
    const [selectedItemLengths, setSelectedItemLengths] = useState({});
    const [Compareref, setCompareref] = useState([]);
    const [senddata, setsenddata] = useState([]);
    const [selecetddowntimegroup, setselecetddowntimegroup] = useState([]);
    const [selectedItemIds, setSelectedItemIds] = useState([]);
    const [compList, setCompList] = useState([])
    const schema = {
        From: joi.date().required().label('From Date'),
        UserId: joi.string(),
        Till: joi.date().required().label('Till Date'),
        Equips: joi.array(),
    }
    const convertDateFormat = (dateString) => {
        const [month, day, year] = dateString.split('-');
        return `${month}-${day}-${year}`;
    };
    const handleOnChange = (e) => {
        if (e.currentTarget.type === 'date') {
            inputData[e.currentTarget.name] = convertDateFormat(e.currentTarget.value);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value;
        }
        setInputData(inputData);
        setErrors({})
    }
    const handleOnSubmit = async (inputData) => {
        productionData.splice(0, productionData.length);
        setIndex(0)
        // consol(inputData)
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)
        const user = auth.getCurrentUser();
        inputData.UserId = user._id
        // consol(inputData,"submited data")
        if (new Date(inputData.From) <= new Date(inputData.Till)) {
        }
        else {
            setQuerySuccess(false);
            return toast.success('From date should be Less Than Till date', {
                position: 'bottom-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    background: '#babbbb', // Set your desired background color
                    color: 'white',     // Set your desired text color
                },
            });
        }
        let equipForReport = getEquipsForReport()
        for (let i = 0; i < equipForReport.length; i++) {
            inputData.Equips = [equipForReport[i]]
            //let data=[...productionData];
            try {
                let result = await axios.put(api.api + 'GetLiveOverviewDashboard', { ...inputData, RequestType: "Historical" })
                if (result.data !== null && result.data !== undefined) {
                    if (result.data.length > 0) {
                        let idx = i + 1
                        result.data.forEach((one) => {
                            // Loop through the properties of the 'one' object
                            for (const key in one) {
                                if (one.hasOwnProperty(key) && one[key] === null || one[key] === undefined) {
                                    one[key] = "-";
                                }
                            }
                            productionData.push(one);
                        });
                        setProductionData(productionData)
                        setIndex(idx)
                    }
                    // else {
                    //   setProductionData([])
                    //   swal('Data Not Available');
                    //   setQuerySuccess(false)
                    // }
                }
                else {
                    setQuerySuccess(false)
                }
            }
            catch (err) {
                toast.success(`${err}`, {
                    position: 'bottom-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    style: {
                        background: 'red', // Set your desired background color
                        color: 'white',     // Set your desired text color
                    },
                });
                // consol(err)
                setQuerySuccess(false)
            }
        }
        setQuerySuccess(false)
    }
    const setSelection = (key, option, equips) => {
        if (key == 'EquipmentGroup') {
            SetEquips(equips ? equips : []);
            setSelectedEquipGroupOption(option)
            setSelectedEquipOption([])
        }
        if (key == 'Equipment') {
            setSelectedEquipOption(option)
        }
    }
    const SelectedDates = (dates) => {
        setInputData(prev => ({ ...prev, ...dates }));
    }
    const getEquipsForReport = () => {
        let equipArr = []
        equips.forEach((one) => {
            selectedEquipOption.forEach((option) => {
                if (one._id == option.value) {
                    equipArr.push(one);
                }
            })
        })
        return equipArr;
    }
    return (
        <React.Fragment>
            {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Operator Performance Board
                </h4>
            </div>
            <ToastContainer />
            <div className="row mx-2">
                {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
                <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
                <div className={productionData.length > 0 ? 'col-sm-1' : 'col-sm-1 d-none'}>
                    <a className='btn btn-sm btn-primary mt-4' onClick={() => handleDownloadTableToExcel('OpPerBoard')} style={{ float: 'right', color: 'white' }}>Download</a>
                </div>
            </div>
            <div >
                {/* <div style={{
                    backgroundColor: "#aee357",
                    padding: '1%'
                }} className="row">
                    <div className="col-sm-7">
                        <span style={{ fontSize: 30, fontWeight: 'bold', color: 'darkred' }}>Operator Performance Board</span>
                    </div>
                    <div className="col-sm-3" style={{ textAlign: 'right' }}>
                        <span style={{ fontSize: 30, fontWeight: 'bold', color: 'darkred' }}>Date : {productionData.filter(x => x).length > 0 ? moment(productionData[0].From).format('DD-MM-YYYY') : '-'}</span>
                    </div>
                    <div className="col-sm-2" style={{ textAlign: 'right' }}>
                        <span style={{ fontSize: 30, fontWeight: 'bold', color: 'darkred' }}>Shift : {productionData.filter(x => x).length > 0 ? productionData[0].Shift : '-'}</span>
                    </div>
                </div> */}
                <div
                    className="table-responsive-sm table-striped">
                    <table
                        id="OpPerBoard"
                        //  style={{
                        //     height: 'calc(100vh - 120px)', // Adjust based on header height
                        //     overflowY: 'auto', // Add scroll if content overflows
                        // }}
                        className="table table-sm table-bordered custom-table-op custom-table">
                        <thead>
                            <tr className="table-primary">
                                <th>M/C</th>
                                <th>DATE</th>
                                <th>SHIFT</th>
                                <th>OPTR.</th>
                                <th>PART</th>
                                <th>TGT</th>
                                <th>ACT</th>
                                <th>REJ</th>
                                <th>DT.(In Mins)</th>
                                <th>%AC</th>
                                <th>%RJ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cloneDeep(productionData).sort((a, b) => b.Shift - a.Shift).map((one, idx) => {
                                return one && (
                                    <tr className="table-success" key={idx.toString()}>
                                        <td>{one.Equipment}</td>
                                        <td>{moment(one.ShiftOf).format('DD-MM-YYYY')}</td>
                                        <td>{one.Shift}</td>
                                        <td>{one.Operators}</td>
                                        <td>{one.Component}</td>
                                        <td>{one.TargetParts}</td>
                                        <td>{one.ActualParts}</td>
                                        <td>{one.Rejected}</td>
                                        <td>{one.DowntimeDuration}</td>
                                        <td>{one.Acheived}</td>
                                        <td>{one.QualityFactor}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}
export default OperatorPerformanceBoard;