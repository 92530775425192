import React, { useEffect, useState } from 'react';
import Table from '../Common/table';
import ColumnChart from '../Charts/ColumnChart';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import { ToastContainer, toast } from 'react-toastify';

import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import DateSelection from '../Common/DateSelection';
import pako from "pako";
import ApexStackedBarChart from '../Charts/ApexStackedBarchart';
import moment from 'moment';
import { Collapse } from 'antd';
import { Radio } from "antd";
import Apexbarchart from '../Charts/ApexBarchart';
const { Panel } = Collapse;
const LiveOperatorWiseProduction = ({ user, equips,equipGroups,fromDateLimit ,storeDataInLocalStorage}) => {
  const [availabilityData, setAvailabilityData] = useState([])
  const [inputData, setInputData] = useState({From:new Date(),Till:new Date()})
  const [errors, setErrors] = useState({})
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [querySuccess, setQuerySuccess] = useState(false)
  const [index, setIndex] = useState(0);
  const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })
  const [activeKey, setActiveKey] = useState(["1"]);
  const [isLive, setIsLive] = useState(true);
  const [Live, setLive] = useState('live');

  const [selectedDates, setSelectedDates] = useState([]);

  const handleChange = (e) => {
    setIsLive(e.target.value === "live");
    setLive(e.target.value);
    setAvailabilityData([])
  };
  useEffect(() => {
   if(Live=='live'){
    handleOnSubmit(inputData)
   }
  }, [Live])
  
  const setSelection=(key,option,equips)=>{
    if(key=='EquipmentGroup'){
        SetEquips(equips?equips:[]);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if(key=='Equipment'){
       
        setSelectedEquipOption(option)
    }
}

  // Function to handle panel changes
  const handlePanelChange = (keys) => {
    setActiveKey(keys); // Updates active keys dynamically
  };

const getEquipsForReport=()=>{
    let equipArr=[]
    equips.forEach((one)=>{
        selectedEquipOption.forEach((option)=>{
            if(one._id==option.value){
                equipArr.push(one);
            }
        })
    })
    return equipArr;
}
  const schema = {
    From: joi.date().required().label('From'),
    Till: joi.date().required().label('Till'),
    UserId: joi.string(),
    Periodicity : joi.string(),
  }


  const columns = [
      { path: 'Date', label: 'Date', content: (obj) => <label>{obj.Date && date.getDateFormat(obj.Date)}</label> },
      { path: 'Shift', label: 'Shift' },
      { path: 'Component', label: 'Component' },
      { path: 'ToolID', label: 'ToolID', content: (obj) => <label>{(obj.ToolID =='null' ||obj.ToolID == null )?'-':obj.ToolID}</label> },
      { path: 'EquipmentGroup', label: 'Equipment Group' },
      { path: 'Equipment', label: 'Equipment' },
      { path: 'Operator', label: 'Operator' },
      { path: 'OperatorID', label: 'Operator ID' },
      { path: 'OperationID', label: 'Operation ID' },
    { path: 'AvailableTime', label: 'Available Time  (mins)' },
    { path: 'DeploymentDuration', label: 'Deployment Duration (mins) ' },
    { path: 'ActualCycles', label: 'Actual Cycles' },
    { path: 'IdealCycles', label: 'Ideal Cycles' },

   
    
    // { path: 'Performance', label: 'Performance (%)', content: (obj) => <label>{(Number(obj.Performance)).toFixed(1)}</label> },
    // { path: 'QualityFactor', label: 'Quality (%)' ,content: (obj) => <label>{(Number(obj.QualityFactor)).toFixed(1)}</label> },
  ]
  const ProducedQtyTable = (OperatorData) => {
    const data = OperatorData.flatMap(item =>
      item.Operation.map(one => ({ ...one, Operator: item.Operator }))
    );
    
    // Get unique operations and operator names
    const operations = [...new Set(data.map((item) => item.OperationID))];
    const operators = [...new Set(data.map((item) => item.Operator))];
  
    // Build the table data
    const tableData = operators.map((operator) => {
      const row = { Operator: operator };
      operations.forEach((operation) => {
        const entry = data.find(
          (item) => item.Operator === operator && item.OperationID === operation
        );
        row[operation] = entry ? entry.PartsProduced : 0;
      });
      return row;
    });
  
    // Calculate totals for each operation
    const totalRow = { Operator: "Total" };
    const TotalArray =[]
    operations.forEach((operation) => {
      TotalArray.push({operation,Total:data
        .filter((item) => item.OperationID === operation)
        .reduce((sum, item) => sum + (item.PartsProduced || 0), 0)})
      totalRow[operation] = data
        .filter((item) => item.OperationID === operation)
        .reduce((sum, item) => sum + (item.PartsProduced || 0), 0);
    });
    return (
      <div className="">
        {operations.length > 0 ? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm m-2' onClick={() => handleDonwLoad('OperatorWiseData', inputData.DateTime)}>Download Excel</button>
        </div> : ''}
        <table id='OperatorWiseData' className="table table-sm table-bordered table-hover small table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Operator Name</th>
              {operations.map((operation) => (
                <th key={operation}>{operation}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.Operator}</td>
                {operations.map((operation) => (
                  <td key={operation}>{row[operation]}</td>
                ))}
              </tr>
            ))}
            {/* Totals Row */}
            <tr className="font-weight-bold">
              <td>{totalRow.Operator}</td>
              {operations.map((operation) => (
                <td key={operation}>{totalRow[operation]}</td>
              ))}
            </tr>
          </tbody>
        </table>
        <br />
        <div style={{backgroundColor:'#5d5c5c',width:'100%',height:'500px'}} className="">

        <Apexbarchart
              jsonData={TotalArray}
              title="Total Parts Produced By Operation"
              header="operation"
              value="Total"
              chartType="bar"
              height="100%"
              orientation="vertical"
              barColor="#2CA02C"
              // textColor={'black'}
              />
              </div>
      </div>
    );
  };
  

  const handleOnSubmit = async (inputData) => {
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    // if (errors) return;
    setQuerySuccess(true)
  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
 
     inputData.RequestType =Live =='live' ?'isLive':'notLive'
      console.log(inputData,"sdfujfjbi");
      try {

      const result = await axios.put(api.api + "LiveOperatorWiseProduction",inputData)
          // const result = await axios.post(api.api + 'GetQualityAnalysis', inputData)
          
          
            // console.log(result.data, "Rejection result received");
            // if (result.data !== null && result.data !== undefined) {
              if (result) {
        
          setAvailabilityData(result.data);
        // }
    }
      }
      catch (err) {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        setQuerySuccess(false)
      }

    
    setQuerySuccess(false)

  }
  const handleDonwLoad = (e, date) => {
    generateExcelFile(e, date)
  }

  const handleSort = (sortColumn) => {
    if (availabilityData.length > 0) {
      const sorted = _.orderBy(
        availabilityData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setAvailabilityData(sorted)
      setSortColumn(sortColumn)
    }
  }
const OrganiseOperatoradta =()=>{

}

  const SelectedDates =(dates)=>{
    setInputData(dates)
  }
  const organizeStackedBarData = (data) => {
    const categories = [];
    const seriesData = {};
  
    data.forEach((item) => {
      const { From, Summary } = item;
      const fromDate = moment(From).format('DD/MM/YYYY'); // Format `From` date
      categories.push(fromDate);
  
      Summary.forEach(({ RejectionReason, Count }) => {
        if (!seriesData[RejectionReason]) {
          seriesData[RejectionReason] = [];
        }
        seriesData[RejectionReason].push(Count);
      });
    });
  
    const series = Object.keys(seriesData).map((reason) => ({
      name: reason,
      data: seriesData[reason],
    }));
  
    return { categories, series };
  };
  
  return (
    <React.Fragment>
      {querySuccess ? <Loading  /> : ''}
      
      <div className="text-center heading-bg mb-2">
        <h4 className="text-white p-0 m-0" >
        Live Operator Performance
        </h4>
      </div>
     
      <ToastContainer/>
      <div style={{margin:'1px'}} className="row  p-1 border">
      {/* {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>} */}
      <div>
      <Radio.Group onChange={handleChange} value={isLive ? "live" : "notLive"}>
        <Radio value="live">Live</Radio>
        <Radio value="notLive">Not Live</Radio>
      </Radio.Group>

      {!isLive && (<div className="row ">

                <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
                <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
      </div>
              
      )}
    </div>
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
         
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
          
        </div> */}
      
        {/* {availabilityData.length > 0 ? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDonwLoad('Availability', inputData.DateTime)}>Download Excel</button>
        </div> : ''} */}
      </div>
      <div>
      <Collapse accordion activeKey={activeKey} onChange={handlePanelChange}>
        {/* ComponentWise Table */}
        {availabilityData && availabilityData?.ComponentWise?.length > 0 && (
          <Panel header="Component Wise Data" key="1"><>
           {availabilityData?.ComponentWise?.length > 0 ? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm m-2' onClick={() => handleDonwLoad('componentWiseData', inputData.DateTime)}>Download Excel</button>
        </div> : ''}
            <Table
              id="componentWiseData"
              onSort={handleSort}
              columns={columns}
              sortColumn={sortColumn}
              data={availabilityData.ComponentWise}
              />
              </>
          </Panel>
        )}

        {/* OperatorWise Table */}
        {availabilityData?.operatorWise?.length > 0 && (
          <Panel header="Operator Wise Data" key="2">
            {ProducedQtyTable(availabilityData.operatorWise)}
          </Panel>
        )}
      </Collapse>
    </div>
    </React.Fragment>


  );
}

export default LiveOperatorWiseProduction;