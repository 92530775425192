import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import { toPng } from "html-to-image";

const ColumnChart = ({ title, data, hAxisTitle, yAxisTitle }) => {
  const chartRef = useRef(null);

  const handleDownload = async () => {
    if (chartRef.current) {
      try {
        // Convert the chart container to a PNG image
        const imageDataURL = await toPng(chartRef.current, {
          backgroundColor: "#ffffff", // Set background color
        });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = imageDataURL;
        downloadLink.download = "chart.png"; // File name
        document.body.appendChild(downloadLink);

        // Trigger the download
        downloadLink.click();

        // Remove the download link from the DOM
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Failed to download chart:", error);
      }
    }
  };

  return (
    <div style={{ width: "100%" }} className="d-flex flex-column">
      <button
        className="btn btn-primary mx-2"
        style={{ height: "40px", width: "160px" }}
        onClick={handleDownload}
      >
        Download Chart
      </button>
      <div style={{ width: "100%" }} ref={chartRef}>
        <Chart
          width={"100%"}
          height={400}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={data}
          options={{
            chart: {
              title: title || "",
            },
            hAxis: {
              title: hAxisTitle,
            },
            vAxis: {
              title: yAxisTitle, // Added Y-Axis title here
            },
          }}
          legendToggle
        />
      </div>
    </div>
  );
};

export default ColumnChart;
