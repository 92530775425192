import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Apexbarchart = ({ 
  jsonData, 
  title, 
  height = '100%', 
  header, 
  value, 
  orientation = 'horizontal', 
  barColor = null, // Single bar color
  barColors = [], // Array of colors for each bar
  textColor = null
}) => {
  // Function to generate unique colors if barColors are not provided
  const generateColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      colors.push(`#00E396`);
    }
    return colors;
  };

  const processDataForChart = (data, xKey, yKey) => {
    return {
      categories: data.map((item) => item[xKey]),
      series: [
        {
          name: yKey,
          data: data.map((item) => item[yKey]),
        },
      ],
    };
  };

  const { categories, series } = processDataForChart(jsonData, header, value);

  // Decide colors: Use single color if `barColor` is present; otherwise, use `barColors`
  const colors = barColor ? [barColor] : (barColors.length > 0 ? barColors : generateColors(categories.length));

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      background: 'transparent',
    },
    title: {
      text: title || '',
      align: 'center',
      style: {
        color: `${textColor ? textColor:'#ffffff'}`,
        fontSize: '16px',
      },
    },
    xaxis: {
      categories: categories || [],
      labels: {
        style: {
          colors: `${textColor ? textColor:'#ffffff'}`,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: `${textColor ? textColor:'#ffffff'}`,
          fontSize: '12px',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: orientation === 'horizontal', // Dynamically set the orientation
        distributed: !barColor, // Use distributed colors only if a single bar color is not provided
        colors: {
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
        },
      },
    },
    colors: colors, // Use either single color or array of colors
    tooltip: {
      theme: 'dark',
    },
    grid: {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    legend: {
      show: false, // Disable the legend
    },
  };

  return (
    <ReactApexChart
      options={barChartOptions}
      series={series}
      type="bar" // Chart type remains bar
      height={height}
      width={"100%"}
    />
  );
};

export default Apexbarchart;
