import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexStackedBarChart = ({ chartData,xaxisTitle,yaxisTiltle }) => {
  const { categories, series } = chartData;
  const uniqueColors = [
    "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#FFC300",
    "#DAF7A6", "#900C3F", "#581845", "#00FF00", "#FF1493",
    "#00FFFF", "#FF4500", "#7FFF00", "#FFD700", "#00BFFF",
    "#9400D3", "#FF6347", "#4682B4", "#8B4513", "#2E8B57",
    "#5F9EA0", "#8A2BE2", "#7B68EE", "#B22222", "#DA70D6",
    "#4B0082", "#CD5C5C", "#6A5ACD", "#FF8C00", "#32CD32",
    "#FF69B4", "#87CEEB", "#FFDAB9", "#1E90FF", "#FFB6C1",
    "#7CFC00", "#BA55D3", "#FFD700", "#5F9EA0", "#ADFF2F",
    "#FF7F50", "#8A2BE2", "#98FB98", "#FF00FF", "#FF4500",
    "#20B2AA", "#FF1493", "#9370DB", "#FF6347", "#2E8B57",
    "#FFFF00", "#00FA9A", "#C71585", "#4682B4", "#6B8E23",
    "#FFA07A", "#00CED1", "#8B4513", "#BDB76B", "#556B2F",
    "#8B008B", "#FFDAB9", "#483D8B", "#FA8072", "#708090",
    "#B22222", "#228B22", "#8B0000", "#D2B48C", "#DAA520",
    "#808000", "#D2691E", "#8FBC8F", "#00BFFF", "#E9967A",
    "#BC8F8F", "#A0522D", "#B0E0E6", "#F0E68C", "#CD5C5C",
    "#F4A460", "#DEB887", "#FFDEAD", "#D8BFD8", "#FFD700",
    "#FF4500", "#DDA0DD", "#FF00FF", "#8A2BE2", "#ADFF2F",
    "#7FFFD4", "#FF6347", "#FA8072", "#FF1493", "#FFA500",
    "#32CD32", "#87CEFA", "#FFDAB9", "#00FFFF", "#FF69B4",
  ];
  
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: true },
      zoom: { enabled: true },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Set bars to horizontal orientation
      },
    },
    colors: uniqueColors, // Use the unique colors for each reason
    xaxis: {
      categories,
      title: { text: xaxisTitle }, // Adjust the title for X-axis
    },
    yaxis: {
      title: { text:yaxisTiltle  }, // Adjust the title for Y-axis
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };
  

  return (
    <ReactApexChart options={options} series={series} type="bar" height={700} />
  );
};

export default ApexStackedBarChart;


