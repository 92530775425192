import React, { Component } from "react";
import Table from "../../../Common/table";
import axios from "../../../httpServices/httpService";
import api from "../../../config";
import swal from "sweetalert";
import _, { reject, update } from "lodash";
import joi from "joi";
import validation from "../../../utill/validation";
import dateTime from "../../../utill/dateTime.js";
import Loading from "./../../../utill/LoadingComponent";
import { MachineStateChart } from "./../../../utill/machineStateChart";
import { Modal, Button, ThemeProvider } from "react-bootstrap";
import MultiSelect from "../../../Common/multiselect";
import JobUpdateForChart from "../JobUpdateModelForChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faFilter,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ComponentView from "../Component/componentView";
import { Dropdown } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { InputNumber, Space, Tabs } from "antd";
import moment from "moment";

class DowntimeView extends Component {
  state = {
    open: false,
    cacheStateList: [],
    equipmentList: [],
    equipmentGroupList: [],
    equipmentTypeList: [],
    EquipmentGroup: "",
    EquipmentType: "",
    EquipmentID: "",
    reasonGroupList: [],
    reasonSplitGroupList: [],

    reasonList: [],
    inputData: { DateTime: new Date(Date.now()), Shift: "Shift1" },
    From: "",
    Till: "",
    oldObj: {},
    newObj: {},
    update: true,
    sortColumn: { path: "Machine", order: "asc" },
    errors: {},
    updateRowIndex: null,
    shiftSetting: [],
    machineStates: [],
    states: [],
    modelState: false,
    updateEvent: {},
    updateEventMultiple: [],
    selectedReason: {},
    entryType: { Single: true, Multiple: false },
    radioStateSingle: "Down",
    systemGenerated: false,
    selectdeReason: {},
    chartEventClickNo: 1,
    modelStateJob: false,
    updateEventJobs: [],
    jobView: { JobView: false },
    updateEquipEvent: {},
    modelStateAdd: false,
    equipFilter: false,
    CycleRun: 0,
    Split: [],
    noOfReasons: 2,
    selectedEquipment:{},
    Entrytype:'Normal',
    SelectedReason:{}
  };
  schema = {
    DateTime: joi.date().required().label("DateTime"),
    Shift: joi.string().required().label("Shift"),
    UserId: joi.string(),
    Equips: joi.array(),
    Remarks: joi.string().allow("").allow(null),
    EndCurrentJob: joi.bool(),
  };

  schemaForModel = {
    Reason: joi.string().required().label("Reason"),
    ReasonGroup: joi.string().required().label("ReasonGroup"),
    Remarks: joi.string().allow("").allow(null),
    EndCurrentJob: joi.bool(),
    _id: joi.string(),
    Color: joi.string(),
    createdAt: joi.string(),
    modified: joi.array(),
    updatedAt: joi.string(),
    ReasonType: joi.string(),
  };

  handleSort = (sortColumn) => {
    // consol(sortColumn);
    if (this.state.downTimeList.length > 0) {
      const sorted = _.orderBy(
        this.state.downTimeList,
        [sortColumn.path],
        [sortColumn.order]
      );
      this.setState({ sortColumn, downTimeList: sorted });
    }
  };

  async componentDidMount() {
    let res = await axios.put(api.api + "GetShiftSettingForDateShift", {
      DateTime: this.state.inputData.DateTime,
    });
    this.setState({ shiftSetting: res.data });

    this.handleClickOK(this.state.inputData);
  }
  //  componentDidMount() {

  // }
  handleReset = () => {
    this.setState({ systemGenerated: false }, () =>
      this.handleClickOK(this.state.inputData)
    );
  };

  handleOnChangeUpdate = (e) => {
    const { oldObj } = { ...this.state };
    oldObj[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ newObj: oldObj });
    // consol(oldObj)
  };
  handleOnChangeDowntime = (e) => {
    const { oldObj } = { ...this.state };
    oldObj[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ newObj: oldObj });
  };
  setDowntimeState = (updateEvent) => {
    return new Promise((resolve, reject) => {
      let filterMac;
      this.state.states.filter((obj) => {
        if (obj.Equipment == updateEvent[0]) {
          filterMac = obj.ListOfEvents.filter((one) => {
            if (
              new Date(updateEvent[4]).getTime() ==
                new Date(one.From).getTime() &&
              new Date(updateEvent[5]).getTime() == new Date(one.Till).getTime()
            ) {
              return one;
            }
          });
        }
      });
      axios
        .patch(api.api2 + "downtime", {
          old: {
            Shift: filterMac[0].Shift,
            Equipment: updateEvent[0],
            State: updateEvent[1],
            From: updateEvent[4],
            Till: updateEvent[5],
          },
          new: {
            Shift: updateEvent[0].Shift,
            Equipment: updateEvent[0],
            Remarks: this.state.selectedReason.Remarks,
            Reason: this.state.selectedReason.Reason,
            EndCurrentJob: this.state.selectedReason.EndCurrentJob,
            ReasonGroup: this.state.selectedReason.ReasonGroup,
            From: updateEvent[4],
            Till: updateEvent[5],
          },
        })
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (n == 1 && nModified == 1 && ok == 1) {
            resolve("Update");
          } else {
            resolve("NotUpdate");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  handleReasonUpdate = async() => {
    delete this.state.selectedReason.__v;
    if(this.state.Entrytype =='Split'){
    console.log('Spliite Functioality',this.state.Entrytype)
        const newObj = [...this.state.Split];
      const oldObj = [...this.state.updateEvent];
      const error = newObj.filter(one => 
        one.DowntimeReason == undefined || one.DowntimeReason?.Reason === '' || one.DowntimeReason?.ReasonGroup === ''
      );
      
      if (error.length > 0) {
        swal('Please Fill Downtime Details');
        return;
      }
      
      // newObj.forEach(one => one.DowntimeReason !== '')

     try {
        const result = await this.setDowntimeState1(oldObj, newObj);
        if (result == "Update") {
          this.setState({ querySuccess: false, modelState: false,Entrytype:'Normal' });
          this.handleClickOK(this.state.inputData)
          swal("Update Success");
        } else {
          this.setState({ querySuccess: false, modelState: false ,Entrytype:'Normal'});
          this.handleClickOK(this.state.inputData)
          swal("Update Success");
        }
      } catch (error) {
        console.error("Error updating downtime:", error);
      }
  }else{
    // return;
    
    const { updateEvent, updateEventMultiple, inputData } = { ...this.state };
    if (this.state.radioStateSingle == "Running") {
      if (this.state.selectedReason == "") {
        swal("Please Select Downtime Reason");
        return;
      }
      if (this.state.entryType.Single) {
        this.setState({ querySuccess: true });
        this.setRunningState(updateEvent)
          .then((result) => {
            if (result == "Update") {
              this.setState({ querySuccess: false, modelState: false });
                    this.setState({Entrytype:'Normal'})

              this.handleUpdateMachineState([updateEvent]);
              
              swal("Update Success");
            } else {
              this.setState({ querySuccess: false, modelState: false });
                    this.setState({Entrytype:'Normal'})

              this.handleUpdateMachineState([updateEvent]);
              swal("Update Success");
            }
          })
          .catch((err) => {
            this.setState({Entrytype:'Normal'})

            this.setState({ querySuccess: false });
            toast.success(`${err}`, {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: "red", // Set your desired background color
                color: "white", // Set your desired text color
              },
            });
          });
      }
      if (this.state.entryType.Multiple) {
        let arr = [];
        this.setState({ querySuccess: true });
        updateEventMultiple.forEach((one) => {
          let filterMac;
          this.state.states.filter((obj) => {
            if (obj.Equipment == one[0]) {
              filterMac = obj.ListOfEvents.filter((one1) => {
                if (
                  new Date(one[4]).getTime() == new Date(one1.From).getTime() &&
                  new Date(one[5]).getTime() == new Date(one1.Till).getTime()
                ) {
                  return one;
                }
              });
            }
          });
          let obj = {
            old: {
              Shift: filterMac[0].Shift,
              Equipment: one[0],
              State: one[1],
              From: one[4],
              Till: one[5],
            },
            new: {
              Equipment: one[0],
              EndCurrentJob: this.state.selectedReason.EndCurrentJob,
              Remarks: this.state.selectedReason.Remarks,
              Reason: this.state.selectedReason.Reason,
              ReasonGroup: this.state.selectedReason.ReasonGroup,
              From: one[4],
              Till: one[5],
            },
          };
          arr.push(obj);
        });
        axios
          .patch(api.api2 + "multipleRunning", { RunningArray: arr })
          .then((result) => {
            if (result.data == "Update") {
              this.setState({ querySuccess: false, modelState: false });
                    this.setState({Entrytype:'Normal'})

              this.handleUpdateMachineState(updateEventMultiple);
              swal("All Reasons Update Successfully");
            } else {
              this.setState({ querySuccess: false, modelState: false });
                    this.setState({Entrytype:'Normal'})

              this.handleUpdateMachineState(updateEventMultiple);
              swal("All Reasons Update Successfully");
            }
          })

          .catch((err) => {
            this.setState({ querySuccess: false });
            toast.success(`${err}`, {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: "red", // Set your desired background color
                color: "white", // Set your desired text color
              },
            });
          });
      }
    } else {
      if (this.state.selectedReason == "") {
        swal("Please Select Downtime Reason");
        return;
      }
      if (this.state.entryType.Single) {
        if (this.state.selectdeReason.Reason !== "Down") {
          this.setState({ querySuccess: true });
          const errors = validation.validate(
            this.state.selectdeReason,
            this.schemaForModel
          );
          // consol(errors)
          this.setState({ errors: errors || {} });
          if (errors) return;
          this.setDowntimeState(updateEvent)
            .then((result) => {
              if (result == "Update") {
                this.setState({ querySuccess: false, modelState: false });
                this.handleUpdateMachineState([updateEvent]);
                swal("Update Success");
              } else {
                this.handleUpdateMachineState([updateEvent]);
                this.setState({ querySuccess: false, modelState: false });
                swal("Not Update Some Internal Error");
              }
            })
            .catch((err) => {
              this.setState({ querySuccess: false });
              toast.success(`${err}`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                  background: "red", // Set your desired background color
                  color: "white", // Set your desired text color
                },
              });
            });
        } else {
          swal("please select reason", { dangerMode: true });
        }
  }

      }

      if (this.state.entryType.Multiple && this.state.radioStateSingle !== "Running") {
        let arr = [];
        this.setState({ querySuccess: true });
        updateEventMultiple.forEach((one) => {
          let filterMac;
          this.state.states.filter((obj) => {
            if (obj.Equipment == one[0]) {
              filterMac = obj.ListOfEvents.filter((one1) => {
                if (
                  new Date(one[4]).getTime() == new Date(one1.From).getTime() &&
                  new Date(one[5]).getTime() == new Date(one1.Till).getTime()
                ) {
                  return one;
                }
              });
            }
          });
          let obj = {
            old: {
              Shift: filterMac[0].Shift,
              Equipment: one[0],
              State: one[1],
              From: one[4],
              Till: one[5],
            },
            new: {
              Equipment: one[0],
              Remarks: this.state.selectdeReason.Remarks,
              EndCurrentJob: this.state.selectdeReason.EndCurrentJob,
              Reason: this.state.selectdeReason.Reason,
              ReasonGroup: this.state.selectdeReason.ReasonGroup,
              From: one[4],
              Till: one[5],
            },
          };
          arr.push(obj);
        });
        axios
          .patch(api.api2 + "multipleDowntime", { DowntimeArray: arr })
          .then((result) => {
            if (result.data == "Update") {
              this.setState({ querySuccess: false, modelState: false });
              this.handleUpdateMachineState(updateEventMultiple);
              swal("All Reasons Update Successfully");
            } else {
              this.setState({ querySuccess: false, modelState: false });
              this.handleUpdateMachineState(updateEventMultiple);
              swal("All Reasons Update Successfully");
            }
          })

          .catch((err) => {
            this.setState({ querySuccess: false });
            toast.success(`${err}`, {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: "red", // Set your desired background color
                color: "white", // Set your desired text color
              },
            });
          });
      }
    }
  };

  handleUpdateMachineState = (downtimeArr) => {
    this.setState({ querySuccess: true });
    const { selectedReason, machineStates } = { ...this.state };
    downtimeArr.forEach((downtime, index) => {
      let oneEquipMacState = machineStates.filter(
        (one) => one.Equipment == downtime[0]
      );
      if (oneEquipMacState.length > 0) {
        if (
          machineStates.filter((one) => one.Equipment == downtime[0])[0]
            .ListOfEvents.length > 0
        ) {
          let filterState = machineStates
            .filter((one) => one.Equipment == downtime[0])[0]
            .ListOfEvents.filter((oneMac) => {
              if (
                new Date(oneMac.From).getTime() ==
                  new Date(downtime[4]).getTime() &&
                new Date(oneMac.Till).getTime() ==
                  new Date(downtime[5]).getTime()
              ) {
                return oneMac;
              }
            });
          if (filterState.length > 0) {
            machineStates
              .filter((one) => one.Equipment == downtime[0])[0]
              .ListOfEvents.filter(
                (oneMac) =>
                  new Date(oneMac.From).getTime() ==
                    new Date(downtime[4]).getTime() &&
                  new Date(oneMac.Till).getTime() ==
                    new Date(downtime[5]).getTime()
              )[0].State = selectedReason.Reason;
            machineStates
              .filter((one) => one.Equipment == downtime[0])[0]
              .ListOfEvents.filter(
                (oneMac) =>
                  new Date(oneMac.From).getTime() ==
                    new Date(downtime[4]).getTime() &&
                  new Date(oneMac.Till).getTime() ==
                    new Date(downtime[5]).getTime()
              )[0].Color = selectedReason.Color;
            machineStates
              .filter((one) => one.Equipment == downtime[0])[0]
              .ListOfEvents.filter(
                (oneMac) =>
                  new Date(oneMac.From).getTime() ==
                    new Date(downtime[4]).getTime() &&
                  new Date(oneMac.Till).getTime() ==
                    new Date(downtime[5]).getTime()
              )[0].DowntimeReason = selectedReason.Reason;
            machineStates
              .filter((one) => one.Equipment == downtime[0])[0]
              .ListOfEvents.filter(
                (oneMac) =>
                  new Date(oneMac.From).getTime() ==
                    new Date(downtime[4]).getTime() &&
                  new Date(oneMac.Till).getTime() ==
                    new Date(downtime[5]).getTime()
              )[0].DowntimeGroup = selectedReason.ReasonGroup;
            machineStates
              .filter((one) => one.Equipment == downtime[0])[0]
              .ListOfEvents.filter(
                (oneMac) =>
                  new Date(oneMac.From).getTime() ==
                    new Date(downtime[4]).getTime() &&
                  new Date(oneMac.Till).getTime() ==
                    new Date(downtime[5]).getTime()
              )[0].Remarks = selectedReason.Remarks
              ? selectedReason.Remarks
              : null;
          }
        }
      }
    });
    this.setState({
      machineStates: machineStates,
      querySuccess: false,
      updateEventMultiple: [],
    });
  };
  
  setDowntimeState1 = async (updateEvent, newObj) => {
    try {
      console.log("Single downtime reason update", updateEvent, newObj);
      const newArray =[]
      let filterMac;
      this.state.states.filter((obj) => {
        if (obj.Equipment == updateEvent[0]) {
          filterMac = obj.ListOfEvents.filter((one) => {
            if (
              new Date(updateEvent[4]).getTime() ==
                new Date(one.From).getTime() &&
              new Date(updateEvent[5]).getTime() == new Date(one.Till).getTime()
            ) {
              return one;
            }
          });
        }
      });
      for (const one of this.state.Split) {
        // Create deep copies of updateEvent to avoid modifying state directly
        newArray.push( {
            Shift: filterMac[0].Shift,
            Equipment: one.Equips[0].EquipmentID,
            Remarks: one.DowntimeReason?.Remarks ?? "",
            Reason: one.DowntimeReason?.Reason,
            EndCurrentJob: one?.EndCurrentJob ?? false,
            ReasonGroup: one.DowntimeReason?.ReasonGroup,
            From: new Date(one.from),
            Till: new Date(one.till),
          })
      }
      const response = await axios.patch(api.api2 + "split-multiple-downtimes", {
        old: {
          Shift: filterMac[0].Shift,
          Equipment: updateEvent[0],
          State: updateEvent[1],
          From: updateEvent[4],
          Till: updateEvent[5],
        },
        new:newArray,
      });
  
      const { n, nModified, ok } = response.data;
      if (n === 1 && nModified === 1 && ok === 1) {
        return "Update";
      } else {
        return "NotUpdate";
      }
    } catch (error) {
      throw error; // Rethrow error for proper handling
    }
  };

  setRunningState = (updateEvent) => {
    return new Promise((resolve, reject) => {
      let filterMac;
      this.state.states.filter((obj) => {
        if (obj.Equipment == updateEvent[0]) {
          filterMac = obj.ListOfEvents.filter((one) => {
            if (
              new Date(updateEvent[4]).getTime() ==
                new Date(one.From).getTime() &&
              new Date(updateEvent[5]).getTime() == new Date(one.Till).getTime()
            ) {
              return one;
            }
          });
        }
      });
      axios
        .patch(api.api2 + "running", {
          old: {
            Shift: filterMac[0].Shift,
            Equipment: updateEvent[0],
            State: updateEvent[1],
            From: updateEvent[4],
            Till: updateEvent[5],
          },
          new: {
            Shift: updateEvent[0].Shift,
            Equipment: updateEvent[0],
            Remarks: this.state.selectedReason.Remarks,
            EndCurrentJob: this.state.selectedReason.EndCurrentJob,
            Reason: this.state.selectedReason.Reason,
            ReasonGroup: this.state.selectedReason.ReasonGroup,
            From: updateEvent[4],
            Till: updateEvent[5],
          },
        })
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (n == 1 && nModified == 1 && ok == 1) {
            resolve("Update");
          } else {
            resolve("NotUpdate");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  handleClickOK = (inputData) => {
    const errors = validation.validate(inputData, this.schema);
    // consol(errors)
    this.setState({ errors: errors || {} });
    if (errors) return;
    inputData.UserId = this.props.user._id;
    inputData.Equips = this.props.equips;
    this.setState({ querySuccess: true, equipFilter: false });
    axios
      .post(api.api + "GetMachineActivityForHistorical", inputData)
      .then((result) => {
        const groupList = [...this.state.equipmentGroupList];
        result.data.forEach((one) => {
          if (
            groupList.filter((group) => group.name == one.EquipmentGroup)
              .length == 0
          ) {
            let obj = { name: one.EquipmentGroup, enable: true };
            groupList.push(obj);
          }
        });

        groupList.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        this.setState({
          equipFilter: false,
          machineStates: result.data,
          equipmentGroupList: groupList,
          states: [...result.data],
          querySuccess: false,
          updateEventMultiple: [],
        });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });

        swal(`${err}`);
      });
  };

  handleOnChange = async (e) => {
    const { inputData, shiftSetting, entryType, jobView } = { ...this.state };
    if (e.currentTarget.type == "date") {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
      let response = await axios.put(api.api + "GetShiftSettingForDateShift", {
        DateTime: inputData.DateTime,
      });
      this.setState({ inputData, shiftSetting: response.data });
    } else if (e.currentTarget.name == "EntryType") {
      if (e.currentTarget.id == "Single") {
        entryType[e.currentTarget.id] = e.currentTarget.checked;
        entryType["Multiple"] = false;
      }
      if (e.currentTarget.id == "Multiple") {
        entryType[e.currentTarget.id] = e.currentTarget.checked;
        entryType["Single"] = false;
      }
      // this.handleClickOK(inputData);
      this.setState({ entryType: entryType });
    } else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ inputData, shiftSetting: shiftSetting });
    }
  };
  // generateSplits = (from, till, splitCount) => {
  //   if (!from || !till || splitCount <= 0) {
  //     throw new Error(
  //       "Invalid inputs. Please provide valid from, till, and splitCount."
  //     );
  //   }

  //   const fromTime = moment(from);
  //   const tillTime = moment(till);

  //   if (
  //     !fromTime.isValid() ||
  //     !tillTime.isValid() ||
  //     fromTime.isAfter(tillTime)
  //   ) {
  //     throw new Error("Invalid date range. Ensure 'from' is before 'till'.");
  //   }

  //   const duration = tillTime.diff(fromTime, "milliseconds");
  //   const interval = duration / (splitCount + 1); // Number of intervals = splits + 1
  //   const splits = [];

  //   let currentStart = fromTime.clone();

  //   for (let i = 0; i <= splitCount; i++) {
  //     const nextSplit = currentStart.clone().add(interval, "milliseconds");
  //     splits.push({
  //       from: currentStart.format("YYYY-MM-DDTHH:mm"),
  //       till: nextSplit.format("YYYY-MM-DDTHH:mm"),
  //     });
  //     currentStart = nextSplit.clone();
  //   }

  //   return splits;
  // };
  generateSplits = (from, till, splitCount, condition,Equipment) => {
    console.log("Generating splits",from, till, splitCount, condition)
    if (!from || !till || splitCount <= 0) {
      throw new Error(
        "Invalid inputs. Please provide valid from, till, and splitCount."
      );
    }
  
    const fromTime = moment(from);
    const tillTime = moment(till);
  
    if (
      !fromTime.isValid() ||
      !tillTime.isValid() ||
      fromTime.isAfter(tillTime)
    ) {
      swal('Cannot find Split,The Split Time Diffrence is Less than The Threshold of the Equipment')
      throw new Error("Invalid date range. Ensure 'from' is before 'till'.");
    }
  
    const duration = tillTime.diff(fromTime, "milliseconds");
    const interval = duration / (splitCount + 1); // Number of intervals = splits + 1
    const intervalInSeconds = interval / 1000;
  
    // Validate that the interval meets the condition
    if (intervalInSeconds < condition) {
      swal("can't split")

      // throw new Error(
      //   `The calculated interval (${intervalInSeconds} seconds) is less than the required condition (${condition} seconds).`
      // );
    }
  
    const splits = [];
    let currentStart = fromTime.clone();
  
    for (let i = 0; i <= splitCount; i++) {
      const nextSplit = currentStart.clone().add(interval, "milliseconds");
      const differenceInSeconds = nextSplit.diff(currentStart, "seconds"); // Difference in seconds
  
      // Push the split only if it meets the condition
      console.log(condition ,"iudgfvfgv",differenceInSeconds)
      if (differenceInSeconds >= condition  ) {
        splits.push({
          from: currentStart.format("YYYY-MM-DDTHH:mm"),
          till: nextSplit.format("YYYY-MM-DDTHH:mm"),
          differenceInSeconds,
          Threshold:condition ,
          Equips:[Equipment]
          // Add difference field
        });
      }else{
        swal("Cannot split. The time is smaller than the threshold.");

      }
  
      currentStart = nextSplit.clone();
    }
  
    return splits;
  };
  
  handleChartEvent = async (evt) => {
    const noOfReasons =2
    let events = evt[1].split("-");
    if (events[0].trim() == "Job") {
      if (this.state.entryType.Single) {
        const machineStates = [...this.state.machineStates];
        let filterEquipmentObj = machineStates.filter(
          (one) => one.Equipment == evt[0]
        );
        this.setState({
          modelStateJob: true,
          updateEventJobs:
            filterEquipmentObj.length > 0 ? filterEquipmentObj[0].Jobs : [],
          updateEquipEvent:
            filterEquipmentObj.length > 0 ? filterEquipmentObj[0] : {},
        });
      } else {
        swal("job update allowed only in Single mode", { dangerMode: true });
      }
    } else {
      if (/*evt[1] !== 'Running' &&*/ evt[1] !== "Break") {
        const selectedEquipment = this.props.equips.find(
          (equip) => equip.EquipmentID === evt[0]
        );
        const inputData = {};
        inputData.From = evt[4];
        inputData.Till = evt[5];
        inputData.Equips = [selectedEquipment];

        console.log(
          selectedEquipment,
          "current machine data",
          inputData,
          // this.generateSplits(evt[4], evt[5], 2,(selectedEquipment && selectedEquipment?.DownTimeThresold ? selectedEquipment.DownTimeThresold : 0))
        );

        this.setState({
          selectedEquipment,
          noOfReasons,
          // Split: this.generateSplits(
          //   evt[4],
          //   evt[5],
          //   noOfReasons - 1,(selectedEquipment && selectedEquipment?.DownTimeThresold ? selectedEquipment.DownTimeThresold : 0),selectedEquipment
          // ),
        });
        let result = await axios.post(api.api + "CyclesRunTimeWise", {
          ReportType: "TimeWise",
          ...inputData,
        });
        if (result.data && result.data.length > 0) {
          this.setState({ CycleRun: result.data[0].Cycles });
        }
        let downtimeReasons = await axios.get(api.api + "downtimegroup");
        let filtered = downtimeReasons.data.filter(
          (item) => item.DownTimeReasonGroup !== "default"
        );
        if (this.state.entryType.Single) {
          let selectdeReason = { ...this.state.selectdeReason };
          let selectedReason = { ...this.state.selectedReason };
          let reasonList = await axios.get(api.api + "downtimereason");
          // let downtimeGroups = await axios.put(api.api + 'downtimereason', { ReasonGroup: reasonList.data.filter(one=>one.Reason==evt[1])[0]._id });
          selectdeReason["Reason"] = evt[1];
          selectedReason["Reason"] = evt[1];
          if (
            reasonList.data.filter((one) => one.Reason == evt[1]).length > 0
          ) {
            selectdeReason["ReasonGroup"] = reasonList.data.filter(
              (one) => one.Reason == evt[1]
            )[0].ReasonGroup;
            selectedReason["ReasonGroup"] = reasonList.data.filter(
              (one) => one.Reason == evt[1]
            )[0].ReasonGroup;
          }
          if (
            this.state.machineStates
              .filter((one) => one.Equipment == evt[0])[0]
              .ListOfEvents.filter(
                (one) =>
                  new Date(one.From).getTime() == new Date(evt[4]).getTime() &&
                  new Date(one.Till).getTime() == new Date(evt[5]).getTime()
              ).length > 0
          ) {
            let remarks = this.state.machineStates
              .filter((one) => one.Equipment == evt[0])[0]
              .ListOfEvents.filter(
                (one) =>
                  new Date(one.From).getTime() == new Date(evt[4]).getTime() &&
                  new Date(one.Till).getTime() == new Date(evt[5]).getTime()
              )[0];
            selectdeReason["Remarks"] = remarks.Remarks;
            selectedReason["Remarks"] = remarks.Remarks;
          }
          this.setState({
            modelState: true,
            reasonList: reasonList.data.filter(
              (one) => one.ReasonGroup == selectdeReason.ReasonGroup
            ),
            selectedReason: selectedReason,
            selectdeReason: selectdeReason,
            SelectedReason: selectdeReason ||selectedReason,

            updateEvent: evt,
            radioStateSingle: evt[1],
            reasonGroupList: filtered,
            chartEventClickNo: this.state.chartEventClickNo + 1,
          });
        }
        if (this.state.entryType.Multiple) {
          const updateEventMultiple = [...this.state.updateEventMultiple];

          let filer = updateEventMultiple.filter(
            (one) => this.getSystemState(one) == this.getSystemState(evt)
          );

          if (updateEventMultiple.length == 0) {
            updateEventMultiple.push(evt);
            this.setState({
              updateEventMultiple,
              reasonGroupList: filtered,
              systemGenerated: false,
            });
          } else {
            if (filer.length > 0) {
              let existsEvent = updateEventMultiple.filter(
                (one) =>
                  new Date(one[4]).getTime() == new Date(evt[4]).getTime() &&
                  new Date(one[5]).getTime() == new Date(evt[5]).getTime() &&
                  one[0] == evt[0]
              );
              if (existsEvent.length > 0) {
              } else {
                updateEventMultiple.push(evt);
                this.setState({
                  updateEventMultiple,
                  reasonGroupList: filtered,
                  systemGenerated: false,
                });
              }
            } else {
              this.setState({ systemGenerated: true });
              swal("update only same system generated state at one time", {
                dangerMode: true,
              });
            }
          }
        }
      }
    }
  };

  handleOnUpdateMultiple = async (events) => {
    if (events.length > 0) {
      let downtimeReasons = await axios.get(api.api + "downtimegroup");
      let filtered = downtimeReasons.data.filter(
        (item) => item.DownTimeReasonGroup !== "default"
      );
      this.setState({
        modelState: true,
        reasonGroupList: filtered,
        selectdeReason: {},
        chartEventClickNo: this.state.chartEventClickNo + 1,
      });
    } else {
      swal("Please select state which you want to update");
    }
  };

  handleOnChangeReasonGroup = async (e,type,idx) => {
  console.log(e.currentTarget,type,idx,"kdfgvkdfvk")
    const selectdeReason = { ...this.state.selectdeReason };
    const reasonSplitGroupList = [ ...this.state.reasonSplitGroupList ];

    
    let obj = JSON.parse(e.currentTarget.value);
    selectdeReason[e.currentTarget.name] = obj.DownTimeReasonGroup;
    let downtimeReasons = await axios.put(api.api + "downtimereason", {
      ReasonGroup: obj._id,
    });
    selectdeReason["Reason"] =downtimeReasons.data.length > 0 ? downtimeReasons.data[0].Reason : "";
if(type === "Split"){

const {Split} = this.state;
const data =[...Split];
data[idx]['DowntimeReason']= selectdeReason

reasonSplitGroupList[idx] ={ selectdeReason ,ReasonList:downtimeReasons.data}


this.setState({
  Split: data,
  reasonSplitGroupList
});
}{


 
    const errors = validation.validate(selectdeReason, this.schemaForModel);
    console.log(errors,"vfdudfub",idx);
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({
      reasonList: downtimeReasons.data,
      selectdeReason: selectdeReason,
      selectedReason: selectdeReason,
      
      errors: errors || {},
    });
}

  };
  handleOnChangeReason = (e,type,idx) => {
    const selectdeReason = { ...this.state.selectdeReason };
    let obj = JSON.parse(e.currentTarget.value);
    selectdeReason[e.currentTarget.name] = obj.Reason;
    const errors = validation.validate(selectdeReason, this.schemaForModel);
    console.log(errors,"bgbfgb",idx)
    this.setState({ errors: errors || {} });
    if (errors) return;
    if(type === 'Split') {
      const {Split} = this.state;
      const data =[...Split];
   
           data[idx]['DowntimeReason'][e.currentTarget.name]= obj.Reason;
    
        this.setState({ Split: data });
      }
    this.setState({
      selectedReason: obj,
      selectdeReason,
      errors: errors || {},
    });
  };

  handleOnChangeRemarks = (e,type,idx) => {
  if(type === 'Split') {
  const {Split} = this.state;
  const data =[...Split];
if (e.currentTarget.name == "EndCurrentJob") {
       data[idx][e.currentTarget.name]= e.currentTarget.checked;

    } else {
       data[idx]['DowntimeReason'][e.currentTarget.name]= e.currentTarget.value;

    }
    this.setState({ Split: data });
  }else{
    const selectedReason = { ...this.state.selectedReason };
    const selectdeReason = { ...this.state.selectdeReason };
    if (e.currentTarget.name == "EndCurrentJob") {
      selectedReason[e.currentTarget.name] = e.currentTarget.checked;
      selectdeReason[e.currentTarget.name] = e.currentTarget.checked;
    } else {
      selectedReason[e.currentTarget.name] = e.currentTarget.value;
      selectdeReason[e.currentTarget.name] = e.currentTarget.value;
    }
    this.setState({
      selectedReason: selectedReason,
      selectdeReason: selectdeReason,
    });
  }
  };

  handleClose = () => {
    this.setState({ modelState: false, chartEventClickNo: 1 });
  };
  handleOnChangeRadio = (e) => {
    if (e.currentTarget.value == "Running") {
      this.setState({
        radioStateSingle: e.currentTarget.value,
        selectedReason: { Reason: "Running", ReasonGroup: null, Remarks: null },
      });
    } else {
      this.setState({
        radioStateSingle: e.currentTarget.value,
        selectedReason: { Reason: "" },
      });
    }
  };

  getSystemState = (evt) => {
    if (evt) {
      let filterMachine = this.state.machineStates.filter(
        (one) => one.Equipment == evt[0]
      );
      if (filterMachine.length > 0) {
        let filterMacDoc = filterMachine[0].ListOfEvents.filter((oneDoc) => {
          if (
            new Date(oneDoc.From).getTime() == new Date(evt[4]).getTime() &&
            new Date(oneDoc.Till).getTime() == new Date(evt[5]).getTime()
          ) {
            return oneDoc;
          }
        });
        if (filterMacDoc.length > 0) return filterMacDoc[0].SystemState;
      }
    }

    return "";
  };

  handleCloseJobModel = () => {
    this.handleClickOK(this.state.inputData);
    this.setState({ modelStateJob: false });
  };

  handleOnJoView = (e) => {
    const jobView = { ...this.state.jobView };
    jobView[e.currentTarget.name] = e.currentTarget.checked;
    this.setState({ jobView: jobView });
  };

  handleSaveJobChanges = async (data1, indexArr) => {
    data1 = data1.filter((one) => one !== undefined || one !== null);
    let data = [...data1];
    // data = this.setDateTimeToData(data)
    // data.From.setMilliseconds(1)
    // if (dateTime.getDifferenceBetweenTimes(data.From, data.Till).minutes < 1) {
    //   swal('From Time and Till Time Diff Should Be Greater Than One Minutes', { dangerMode: true })
    //   return
    // }
    // if (data.From > data.Till) {
    //   swal('from time is should be less than till time', { dangerMode: true })
    //   return
    // }

    if (data[0].From > data[0].Till) {
      swal("from time is should be less than till time", { dangerMode: true });
      return;
    }

    this.setState({ querySuccess: true });
    let okArr = [];
    for (let i = 0; i < data.length; i++) {
      try {
        let from = new Date(data[i].From);
        from.setMilliseconds(1);
        data[i].From = from;
        let oldObj = this.state.updateEventJobs[data[i].index];
        let res = await axios.patch(api.api2 + "jobselected/" + data[i]._id, {
          old: oldObj,
          new: data[i],
        });
        const { n, nModified, ok } = res.data;
        if (ok == 1 && n == 1 && nModified == 1) {
          okArr.push("OK");
        }
      } catch (err) {
        if (err.response) {
          swal(`${err.response.data.message}`);
        } else {
          toast.success(`${err}`, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: "red", // Set your desired background color
              color: "white", // Set your desired text color
            },
          });
        }
        break;
      }
    }
    this.setState({ querySuccess: false });
    const machineStates = [...this.state.machineStates];
    const updateEquipEvent = { ...this.state.updateEquipEvent };
    const updateEventJobs = [...this.state.updateEventJobs];
    if (okArr.length == data.length) {
      updateEventJobs.forEach((one, index) => {
        data.forEach((obj) => {
          updateEventJobs[obj.index] = obj;
        });
      });
      updateEquipEvent.Jobs = updateEventJobs;
      //this.handleClickOK(this.state.inputData)
      this.setState({
        updateEquipEvent: updateEquipEvent,
        updateEventJobs: updateEventJobs,
      });
      swal("Update Data Successfully");
    }
  };

  handleClickAddNew = () => {
    this.setState({ modelStateAdd: true });
  };
  handleCloseNewModal = () => {
    this.handleClickOK(this.state.inputData);
    this.setState({ modelStateAdd: false });
  };

  handleDeleteJob = (data) => {
    swal({
      title: "Are you sure?",
      text: "Removing a job will require you to manually manage the qaulity document for this job.Please do accordingly",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api2 + "jobselected/" + data._id, { data: data })
          .then((result) => {
            // consol(result.data)
            if (result.data.ok === 1 && result.data.deletedCount === 1) {
              const machineStates = [...this.state.machineStates];
              const updateEquipEvent = { ...this.state.updateEquipEvent };
              let filterData = this.state.updateEventJobs.filter(
                (obj) => obj._id !== data._id
              );
              let filterMacState = machineStates.filter(
                (one) => one.Equipment == data.Equipment
              );
              if (filterMacState.length > 0) {
                machineStates.filter(
                  (one) => one.Equipment == data.Equipment
                )[0].Jobs = machineStates
                  .filter((one) => one.Equipment == data.Equipment)[0]
                  .Jobs.filter((job) => job._id !== data._id);
              }
              updateEquipEvent.Jobs = filterData;
              this.setState({
                machineStates: machineStates,
                updateEventJobs: filterData,
                updateEquipEvent: updateEquipEvent,
                states: [...machineStates],
                querySuccess: false,
                updateEventMultiple: [],
              });
            }
          })

          .catch((err) => {
            // console.log(err.response,"vhgsdjcvs");
            this.setState({ querySuccess: false });
            swal(`${err.response?.data?.message}`);
          });
      } else {
        this.setState({ querySuccess: false });
        // swal("Your imaginary file is safe!");
      }
    });
  };

  handleOnChangeFilter = (event) => {
    let groupList = [...this.state.equipmentGroupList];
    groupList.filter(
      (group) => group.name == event.currentTarget.name
    )[0].enable = event.currentTarget.checked;
    this.setState({ equipmentGroupList: groupList, equipFilter: true });
  };

  setFilterView = (state) => {
    this.setState({ equipFilter: state });
  };
  // handleSplitTimeChange = (e, idx, type,condition) => {
  //   const data = [...this.state.Split];
    
  //   // Get the selected time
  //   const selectedTime = e.currentTarget.value; // Example: "14:30"
  
  //   // Combine the current date with the selected time
  //   const currentDate = new Date(); // Get today's date
  //   const [hours, minutes] = selectedTime.split(":");
  //   const combinedDateTime = new Date(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth(),
  //     currentDate.getDate(),
  //     parseInt(hours, 10),
  //     parseInt(minutes, 10)
  //   );
  
  //   // Update the Split data
  //   data[idx][type] = combinedDateTime.toISOString(); // Store as ISO string or desired format
  //   if(type ==='till'){

  //     data[idx+1]['from'] = combinedDateTime.toISOString(); // Store as ISO string or desired format
  //   }
  
  //   this.setState({ Split: data });
  
  //   console.log(
  //     data,
  //     "Updated data with datetime:",
  //     combinedDateTime,
  //     "Current target value:",
  //     e.currentTarget.value,
  //     "Index:",
  //     idx
  //   );
  // };
  handleSplitTimeChange = (e, idx, type, condition) => {
    const data = [...this.state.Split];
    const selectedTime = e.currentTarget.value; // Example: "14:30"
  
    // Combine the current date with the selected time
    const currentDate = new Date();
    const [hours, minutes] = selectedTime.split(":");
    const combinedDateTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      parseInt(hours, 10),
      parseInt(minutes, 10)
    );
  
    // Check if the updated 'till' is greater than its corresponding 'from'
    if (type === "till" && combinedDateTime <= new Date(data[idx]?.from)) {
      swal("The 'till' time must be greater than the 'from' time.");
      return;
    }
  
    // Update the Split data
    data[idx][type] = combinedDateTime.toISOString();
  
    // If 'till' is updated, update the next 'from'
    if (type === "till" && idx + 1 < data.length) {
      data[idx + 1]["from"] = combinedDateTime.toISOString();
    }
  
    // Check time differences for all splits
    for (let i = 0; i < data.length; i++) {
      const fromTime = new Date(data[i]?.from);
      const tillTime = new Date(data[i]?.till);
  
      // Ensure both 'from' and 'till' are defined before calculating the difference
      if (fromTime && tillTime) {
        const differenceInSeconds = (tillTime - fromTime) / 1000; // Difference in seconds
  
        // Push the split only if it meets the condition
        console.log(data[i].Threshold, "testing while changing", differenceInSeconds,"consition ",differenceInSeconds >= data[i].Threshold);
        if (differenceInSeconds >= data[i].Threshold) {
          // data[i].differenceInSeconds = differenceInSeconds;
        } else {
          swal(
            "Cannot split. The time difference between splits does not meet the time threshold."
          );
          return;
        }
      }
    }
  
    // Update state with the modified Split data
    this.setState({ Split: data });
  
    console.log(
      data,
      "Updated data with datetime:",
      combinedDateTime,
      "Current target value:",
      e.currentTarget.value,
      "Index:",
      idx
    );
  };
  handleTabChange = (activeKey) => {
    const {selectedEquipment,updateEvent,noOfReasons} = this.state;
    console.log("Active Tab:", activeKey);
    // Call your desired function here
    if (activeKey === "1") {
      console.log("Single Downtime Reason tab selected");
      // Call specific function for this tab
      this.setState({
      Entrytype:'Normal',
selectdeReason:{},
selectedReason:{},
Split:[]
      })
    } else if (activeKey === "2") {
this.setState({Split:this.generateSplits(
  updateEvent[4],
  updateEvent[5],
  noOfReasons - 1,(selectedEquipment && selectedEquipment?.DownTimeThresold ? selectedEquipment.DownTimeThresold : 0)
  ,selectedEquipment),

  Entrytype:'Split',
selectdeReason:{},
selectedReason:{},

}); console.log("Multiple Downtime Reason tab selected");
      // Call specific function for this tab
    }
    
  };
  
  render() {
    const {
      errors,
      shiftSetting,
      machineStates,
      querySuccess,
      noOfReasons,
      CycleRun,
      Split,
      modelState,
      updateEvent,
      reasonList,
      reasonGroupList,
      radioStateSingle,
      modelStateJob,
      updateEventJobs,
      modelStateAdd,
      selectedEquipment,reasonSplitGroupList
    } = this.state;
    const { getRightsForAccess } = this.props;

    console.log(reasonSplitGroupList,"cuh80dcuiduidcuidcdc")
    return (
      <React.Fragment>
        {" "}
        <ToastContainer />
        {this.state.querySuccess ? <Loading /> : ""}
        <div className="text-center">
          <p>Downtime Reason Entry</p>
        </div>
        <Modal
          show={modelStateAdd}
          onHide={this.handleCloseNewModal}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Job</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ComponentView
              flag="Downtime"
              getRightsForAccess={getRightsForAccess}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseNewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <JobUpdateForChart
          updateEquipObj={this.state.updateEquipEvent}
          handleDeleteJob={this.handleDeleteJob}
          handleSaveJobChanges={this.handleSaveJobChanges}
          Jobs={[...updateEventJobs]}
          modelState={modelStateJob}
          handleClose={this.handleCloseJobModel}
        />
        <Modal
          show={modelState}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 15,width:'100%' }}>
              {updateEvent[0]} Downtime Reason Entry
              <div className="d-flex w-100 gap-2">
              <table className="table table-sm table-bordered table-hover small table-striped m-0">
              <thead className="thead-dark">
                    <tr>
                      <th>Date</th>
                      <th>Machine</th>
                      <th>Shift</th>
                      <th>From</th>
                      <th>Till</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.entryType.Single ||
                    this.state.entryType.Single == "true" ? (
                      <tr>
                        <td>{dateTime.getDateFormat(updateEvent[4])}</td>
                        <td>{updateEvent[0]}</td>
                        <td>{this.state.inputData.Shift}</td>
                        <td>{dateTime.getTime(updateEvent[4])}</td>
                        <td>{dateTime.getTime(updateEvent[5])}</td>
                      </tr>
                    ) : (
                      this.state.updateEventMultiple.map((one) => {
                        return (
                          <tr>
                            <td>{dateTime.getDateFormat(one[4])}</td>
                            <td>{one[0]}</td>
                            <td>{this.state.inputData.Shift}</td>
                            <td>{dateTime.getTime(one[4])}</td>
                            <td>{dateTime.getTime(one[5])}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <div
                  style={{ height: "64px" }}
                  className="text-nowrap p-2 bg-light d-flex align-items-center"
                >
                  Cycles Run : {CycleRun}
                </div>
              </div>
              {this.state.entryType.Single ||
              this.state.entryType.Single == "true" ? (
                this.getSystemState(updateEvent) == "Running" ? (
                  <>
                    <input
                      type="radio"
                      value="Down"
                      onChange={this.handleOnChangeRadio}
                      checked={radioStateSingle !== "Running"}
                      name="state"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;<label>Down</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      value="Running"
                      onChange={this.handleOnChangeRadio}
                      checked={radioStateSingle == "Running"}
                      name="state"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;<label>Running</label>
                  </>
                ) : (
                  <></>
                )
              ) : this.getSystemState(this.state.updateEventMultiple[0]) ==
                "Running" ? (
                <>
                  <input
                    type="radio"
                    value="Down"
                    onChange={this.handleOnChangeRadio}
                    checked={radioStateSingle !== "Running"}
                    name="state"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;<label>Down</label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    value="Running"
                    onChange={this.handleOnChangeRadio}
                    checked={radioStateSingle == "Running"}
                    name="state"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;<label>Running</label>
                </>
              ) : (
                ""
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {console.log(radioStateSingle,"fduuhdiufh",this.state.entryType,this.state.SelectedReason,this.getSystemState(updateEvent),updateEvent)}
            { this.state.entryType.Multiple== true ? 
             <div className="">
                <div
                  hidden={radioStateSingle == "Running"}
                  className="form-group text-center"
                >
                  <label className="small">Downtime Group</label>
                  {/* <select className='form-control form-control-sm' onChange={this.handleOnChangeReasonGroup} name='ReasonGroup'>
                <option>--Select Downtime Reasons Groups--</option>
                {reasonGroupList.map(one => {
                  return <option value={JSON.stringify(one)}>{one.DownTimeReasonGroup}</option>
                  })}
                  </select> */}
                  <MultiSelect
                    name="ReasonGroup"
                    masterName="DownTimeReasonGroup"
                    objLevel={1}
                    all={reasonGroupList}
                    selected={this.state.selectdeReason["ReasonGroup"]}
                    handleOnChange={this.handleOnChangeReasonGroup}
                  />
                  {errors.ReasonGroup && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors.ReasonGroup}
                    </p>
                  )}
                </div>
                <div
                  hidden={radioStateSingle == "Running"}
                  className="form-group text-center"
                >
                  <label className="small">Downtime Reason</label>
                  {/* <select className='form-control form-control-sm' onChange={this.handleOnChangeReason} name='Reason'>
                <option>--Select Downtime Reasons--</option>
                {reasonList.map(one => {
                  return <option value={JSON.stringify(one)}>{one.Reason}</option>
                })}
                </select> */}
                  <MultiSelect
                    name="Reason"
                    masterName="Reason"
                    objLevel={1}
                    all={reasonList}
                    selected={this.state.selectdeReason["Reason"]}
                    handleOnChange={this.handleOnChangeReason}
                  />

                  {errors.Reason && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors.Reason}
                    </p>
                  )}
                </div>
                <div className="form-group text-center">
                  <label className="small">Remarks</label>
                  <textarea
                    rows="2"
                    cols="65"
                    maxLength="50"
                    value={this.state.selectdeReason.Remarks}
                    placeholder="enter remarks..."
                    onChange={this.handleOnChangeRemarks}
                    className="form-control form-control-sm"
                    name="Remarks"
                  />
                </div>

                <div className="form-group text-center ">
                  <label
                    className="form-check-label small mr-4 "
                    for="flexCheckChecked"
                  >
                    End Current Job
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    disabled={this.state.entryType.Multiple}
                    onChange={this.handleOnChangeRemarks}
                    name="EndCurrentJob"
                  />
                </div>
              </div> : ((this.state.entryType.Single== true && this.getSystemState(updateEvent) !== "Running"))?  
              <Tabs
                  defaultActiveKey="1"
                  type="card"
                  size={"small"}
                  onChange={this.handleTabChange }
                  items={[
                    "Single Downtime Reason",
                    "Multiple Downtime Reason",
                  ].map((_, i) => {
                    const id = String(i + 1);
                    return {
                      label: `${_}`,
                      key: id,
                      children: (
                        <>
                          {id == 1 ? (
                            <div>
                              {" "}
                              <div
                                hidden={radioStateSingle == "Running"}
                                className="form-group text-center"
                              >
                                <label className="small">Downtime Group</label>

                                <MultiSelect
                                  name="ReasonGroup"
                                  masterName="DownTimeReasonGroup"
                                  objLevel={1}
                                  all={reasonGroupList}
                                  selected={
                                    this.state.selectdeReason["ReasonGroup"]
                                  }
                                  handleOnChange={
                                    this.handleOnChangeReasonGroup
                                  }
                                />
                                {errors.ReasonGroup && (
                                  <p
                                    className="text-danger "
                                    style={{ fontSize: 11 }}
                                  >
                                    {errors.ReasonGroup}
                                  </p>
                                )}
                              </div>
                              <div
                                hidden={radioStateSingle == "Running"}
                                className="form-group text-center"
                              >
                                <label className="small">Downtime Reason</label>

                                <MultiSelect
                                  name="Reason"
                                  masterName="Reason"
                                  objLevel={1}
                                  all={reasonList}
                                  selected={this.state.selectdeReason["Reason"]}
                                  handleOnChange={this.handleOnChangeReason}
                                />

                                {errors.Reason && (
                                  <p
                                    className="text-danger "
                                    style={{ fontSize: 11 }}
                                  >
                                    {errors.Reason}
                                  </p>
                                )}
                              </div>
                              <div className="form-group text-center">
                                <label className="small">Remarks</label>
                                <textarea
                                  rows="2"
                                  cols="65"
                                  maxLength="50"
                                  value={this.state.selectdeReason.Remarks}
                                  placeholder="enter remarks..."
                                  onChange={this.handleOnChangeRemarks}
                                  className="form-control form-control-sm"
                                  name="Remarks"
                                />
                              </div>
                              <div className="form-group text-center ">
                                <label
                                  className="form-check-label small mr-4 "
                                  for="flexCheckChecked"
                                >
                                  End Current Job
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  disabled={this.state.entryType.Multiple}
                                  onChange={this.handleOnChangeRemarks}
                                  name="EndCurrentJob"
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="p-2 border">
                                {/* Input for Number of Reasons */}
                                <div >
                                  <label htmlFor="noOfReasons">
                                    No. of Reasons :
                                  </label>

                                  <Space>
                                    <InputNumber
                                      onKeyDown={(e) => e.preventDefault()} // Prevent key presses
                                      onKeyPress={(e) => e.preventDefault()} // Prevent other keyboard actions
                                      onPaste={(e) => e.preventDefault()} // Prevent pasting into the input
                                      min={2}
                                      max={10}
                                      value={noOfReasons}
                                      onChange={(e) => {
                                        console.log(e, "duhvk");

                                        const reasonsCount = Number(e);
                                        if (reasonsCount > 0) {
                                          // console.log(this.generateSplits(updateEvent[4],updateEvent[5],reasonsCount),"current split data")
                                          this.setState({
                                            Split: this.generateSplits(
                                              updateEvent[4],
                                              updateEvent[5],
                                              reasonsCount - 1,(selectedEquipment && selectedEquipment?.DownTimeThresold ? selectedEquipment.DownTimeThresold : 0)
                                              ,selectedEquipment),
                                            noOfReasons: e,
                                          });
                                        } else {
                                          this.setState({ Split: [] });
                                        }
                                      }}
                                    />
                                    {/* <Button
        type="primary"
        onClick={() => {
          setValue(2);
        }}
      >
        Reset
      </Button> */}
                                  </Space>
                                </div>

                                <label className="mx-2">From :</label>
                                <input
                                  type="datetime-local"
                                  disabled
                                  value={
                                    updateEvent[4]
                                      ? moment(updateEvent[4]).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      : ""
                                  }
                                  name="split-212"
                                />

                                {/* Split Inputs */}
                                {Split?.map((_, idx) => (
                                  <div className="border" key={idx}>
                                    <div class="container text-center">
                                      <div class="row">
                                        <div class="col-sm-3 p-2">
                                          <div className="row">
                                            <div class="col-sm-9 p-1">
                                              <div className="form-group text-center ">
                                                <label>
                                                  &nbsp;&nbsp;&nbsp;From :{" "}
                                                  {idx + 1}
                                                </label>
                                                <input
                                                  disabled
                                                  value={moment(_.from).format(
                                                    "HH:mm"
                                                  )}
                                                  onChange={(e)=>{this.handleSplitTimeChange(e,idx,'from',{from:moment(updateEvent[4]).format(
                                                    "YYYY-MM-DDTHH:mm"
                                                  ),till:moment(updateEvent[5]).format(
                                                    "YYYY-MM-DDTHH:mm"
                                                  )})}}
                                                  type="time"
                                                  name={`from-${idx + 1}`}
                                                 
                                                />
                                              </div>
                                            </div>
                                            <div class="col-sm-9 p-1">
                                              <div className="form-group text-center ">
                                                <label>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;Till :{" "}
                                                  {idx + 1}
                                                </label>
                                                <input
                                                  min={"08:00"}
                                                  max={"18:00"}
                                                  onChange={(e)=>{this.handleSplitTimeChange(e,idx,'till',{from:moment(updateEvent[4]).format(
                                                    "YYYY-MM-DDTHH:mm"
                                                  ),till:moment(updateEvent[5]).format(
                                                    "YYYY-MM-DDTHH:mm"
                                                  )})}}

                                                  disabled={
                                                    idx == Split.length - 1
                                                      ? true
                                                      : false
                                                  }
                                                  value={moment(_.till).format(
                                                    "HH:mm"
                                                  )}
                                                  type="time"
                                                  name={`till-${idx + 1}`}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-9">
                                          <div class="row">
                                            <div class="col-8 col-sm-6">
                                              <div
                                                hidden={
                                                  radioStateSingle == "Running"
                                                }
                                                className="form-group text-center "
                                              >
                                                <label className="small">
                                                  Downtime Group
                                                </label>

                                                <MultiSelect
                                                  name="ReasonGroup"
                                                  masterName="DownTimeReasonGroup"
                                                  objLevel={1}
                                                  all={reasonGroupList}
                                                  selected={
                                                  (reasonSplitGroupList &&  reasonSplitGroupList[idx] && reasonSplitGroupList[idx].selectdeReason
                                                    [
                                                      "ReasonGroup"] )?? ''
                                                  }
                                                  handleOnChange={(e)=>
                                                    this.handleOnChangeReasonGroup(e,'Split',idx)
                                                  }
                                                />
                                                {console.log(reasonSplitGroupList,"dwiudui")}
                                                {errors.ReasonGroup && (
                                                  <p
                                                    className="text-danger "
                                                    style={{ fontSize: 11 }}
                                                  >
                                                    {errors.ReasonGroup}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                            <div class="col-4 col-sm-6">
                                              <div
                                                hidden={
                                                  radioStateSingle == "Running"
                                                }
                                                className="form-group text-center "
                                              >
                                                <label className="small">
                                                  Downtime Reason
                                                </label>

                                                <MultiSelect
                                                  name="Reason"
                                                  masterName="Reason"
                                                  objLevel={1}
                                                  all={(reasonSplitGroupList && reasonSplitGroupList[idx] && reasonSplitGroupList[idx].ReasonList) ? reasonSplitGroupList[idx].ReasonList:[]}
                                                  selected={
                                                    (_ && _?.DowntimeReason && _.DowntimeReason[
                                                      "Reason"
] )?? ''
                                                  }
                                                  handleOnChange={
                                                    (e)=>this.handleOnChangeReason(e,"Split",idx)
                                                  }
                                                />
                                                {errors.Reason && (_ && _?.DowntimeReason && _.DowntimeReason["Reason"] )  =='' && (
                                                  <p
                                                    className="text-danger "
                                                    style={{ fontSize: 11 }}
                                                  >
                                                    {errors.Reason}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                            <div class="col-4 col-sm-6">
                                              <div className="form-group text-center">
                                                <label className="small">
                                                  Remarks
                                                </label>
                                                <textarea
                                                  rows="2"
                                                  cols="65"
                                                  maxLength="50"
                                                  value={
                                                    (_ && _?.DowntimeReason && _.DowntimeReason["Remarks"] )?? ''}
                                                
                                                  placeholder="enter remarks..."
                                                  onChange={(e)=>
                                                    this.handleOnChangeRemarks(e,"Split",idx)
                                                  }
                                                  className="form-control form-control-sm"
                                                  name="Remarks"
                                                />
                                              </div>
                                            </div>
                                            <div class="col-4 col-sm-6">
                                              <div className="form-group text-center d-flex p-3">
                                                <label
                                                  className="form-check-label small  "
                                                  for="flexCheckChecked"
                                                >
                                                  End Current Job
                                                </label>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={ (_ && _?.EndCurrentJob && _.EndCurrentJob)?_.EndCurrentJob : false}
                                                  disabled={
                                                    this.state.entryType
                                                      .Multiple
                                                  }
                                                  onChange={(e)=>this.handleOnChangeRemarks(e,"Split",idx)
                                                  }
                                                  name="EndCurrentJob"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                                {/* { old: { Shift: , Equipment:, State: , From: , Till:  }, new: { Shift: , Equipment: , Remarks: , Reason: , EndCurrentJob: , ReasonGroup: , From: , Till:} } */}
                                {/* Till Date/Time */}
                                <label className="m-2">Till :</label>
                                <input
                                  type="datetime-local"
                                  disabled
                                  value={
                                    updateEvent[5]
                                      ? moment(updateEvent[5]).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      : ""
                                  }
                                  name="split-212"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ),
                    };
                  })}
                /> :this.state.entryType.Single== true &&  this.state.SelectedReason.Reason == "Running"?
                <div className="">
                <div
                  hidden={radioStateSingle == "Running"}
                  className="form-group text-center"
                >
                  <label className="small">Downtime Group</label>
                  {/* <select className='form-control form-control-sm' onChange={this.handleOnChangeReasonGroup} name='ReasonGroup'>
                <option>--Select Downtime Reasons Groups--</option>
                {reasonGroupList.map(one => {
                  return <option value={JSON.stringify(one)}>{one.DownTimeReasonGroup}</option>
                  })}
                  </select> */}
                  <MultiSelect
                    name="ReasonGroup"
                    masterName="DownTimeReasonGroup"
                    objLevel={1}
                    all={reasonGroupList}
                    selected={this.state.selectdeReason["ReasonGroup"]}
                    handleOnChange={this.handleOnChangeReasonGroup}
                  />
                  {errors.ReasonGroup && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors.ReasonGroup}
                    </p>
                  )}
                </div>
                <div
                  hidden={radioStateSingle == "Running"}
                  className="form-group text-center"
                >
                  <label className="small">Downtime Reason</label>
                  {/* <select className='form-control form-control-sm' onChange={this.handleOnChangeReason} name='Reason'>
                <option>--Select Downtime Reasons--</option>
                {reasonList.map(one => {
                  return <option value={JSON.stringify(one)}>{one.Reason}</option>
                })}
                </select> */}
                  <MultiSelect
                    name="Reason"
                    masterName="Reason"
                    objLevel={1}
                    all={reasonList}
                    selected={this.state.selectdeReason["Reason"]}
                    handleOnChange={this.handleOnChangeReason}
                  />

                  {errors.Reason && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors.Reason}
                    </p>
                  )}
                </div>
                <div className="form-group text-center">
                  <label className="small">Remarks</label>
                  <textarea
                    rows="2"
                    cols="65"
                    maxLength="50"
                    value={this.state.selectdeReason.Remarks}
                    placeholder="enter remarks..."
                    onChange={this.handleOnChangeRemarks}
                    className="form-control form-control-sm"
                    name="Remarks"
                  />
                </div>

                <div className="form-group text-center ">
                  <label
                    className="form-check-label small mr-4 "
                    for="flexCheckChecked"
                  >
                    End Current Job
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    disabled={this.state.entryType.Multiple}
                    onChange={this.handleOnChangeRemarks}
                    name="EndCurrentJob"
                  />
                </div>
              </div>:
              <div className="">
                <div
                  hidden={radioStateSingle == "Running"}
                  className="form-group text-center"
                >
                  <label className="small">Downtime Group</label>
                  {/* <select className='form-control form-control-sm' onChange={this.handleOnChangeReasonGroup} name='ReasonGroup'>
                <option>--Select Downtime Reasons Groups--</option>
                {reasonGroupList.map(one => {
                  return <option value={JSON.stringify(one)}>{one.DownTimeReasonGroup}</option>
                  })}
                  </select> */}
                  <MultiSelect
                    name="ReasonGroup"
                    masterName="DownTimeReasonGroup"
                    objLevel={1}
                    all={reasonGroupList}
                    selected={this.state.selectdeReason["ReasonGroup"]}
                    handleOnChange={this.handleOnChangeReasonGroup}
                  />
                  {errors.ReasonGroup && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors.ReasonGroup}
                    </p>
                  )}
                </div>
                <div
                  hidden={radioStateSingle == "Running"}
                  className="form-group text-center"
                >
                  <label className="small">Downtime Reason</label>
                  {/* <select className='form-control form-control-sm' onChange={this.handleOnChangeReason} name='Reason'>
                <option>--Select Downtime Reasons--</option>
                {reasonList.map(one => {
                  return <option value={JSON.stringify(one)}>{one.Reason}</option>
                })}
                </select> */}
                  <MultiSelect
                    name="Reason"
                    masterName="Reason"
                    objLevel={1}
                    all={reasonList}
                    selected={this.state.selectdeReason["Reason"]}
                    handleOnChange={this.handleOnChangeReason}
                  />

                  {errors.Reason && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors.Reason}
                    </p>
                  )}
                </div>
                <div className="form-group text-center">
                  <label className="small">Remarks</label>
                  <textarea
                    rows="2"
                    cols="65"
                    maxLength="50"
                    value={this.state.selectdeReason.Remarks}
                    placeholder="enter remarks..."
                    onChange={this.handleOnChangeRemarks}
                    className="form-control form-control-sm"
                    name="Remarks"
                  />
                </div>

                <div className="form-group text-center ">
                  <label
                    className="form-check-label small mr-4 "
                    for="flexCheckChecked"
                  >
                    End Current Job
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    disabled={this.state.entryType.Multiple}
                    onChange={this.handleOnChangeRemarks}
                    name="EndCurrentJob"
                  />
                </div>
              </div>}
            {/* {this.state.entryType.Single== true ?  (
              <div className="">
                <div
                  hidden={radioStateSingle == "Running"}
                  className="form-group text-center"
                >
                  <label className="small">Downtime Group</label>
                 
                  <MultiSelect
                    name="ReasonGroup"
                    masterName="DownTimeReasonGroup"
                    objLevel={1}
                    all={reasonGroupList}
                    selected={this.state.selectdeReason["ReasonGroup"]}
                    handleOnChange={this.handleOnChangeReasonGroup}
                  />
                  {errors.ReasonGroup && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors.ReasonGroup}
                    </p>
                  )}
                </div>
                <div
                  hidden={radioStateSingle == "Running"}
                  className="form-group text-center"
                >
                  <label className="small">Downtime Reason</label>
                 
                  <MultiSelect
                    name="Reason"
                    masterName="Reason"
                    objLevel={1}
                    all={reasonList}
                    selected={this.state.selectdeReason["Reason"]}
                    handleOnChange={this.handleOnChangeReason}
                  />

                  {errors.Reason && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors.Reason}
                    </p>
                  )}
                </div>
                <div className="form-group text-center">
                  <label className="small">Remarks</label>
                  <textarea
                    rows="2"
                    cols="65"
                    maxLength="50"
                    value={this.state.selectdeReason.Remarks}
                    placeholder="enter remarks..."
                    onChange={this.handleOnChangeRemarks}
                    className="form-control form-control-sm"
                    name="Remarks"
                  />
                </div>

                <div className="form-group text-center ">
                  <label
                    className="form-check-label small mr-4 "
                    for="flexCheckChecked"
                  >
                    End Current Job
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    disabled={this.state.entryType.Multiple}
                    onChange={this.handleOnChangeRemarks}
                    name="EndCurrentJob"
                  />
                </div>
              </div>
            ) : ( */}
              {/* <>
                {this.state.updateEventMultiple.length < 2 && this.state.entryType.Single== true &&   this.getSystemState(updateEvent) !== "Running" &&  radioStateSingle !== "Down" &&
                <Tabs
                  defaultActiveKey="1"
                  type="card"
                  size={"small"}
                  onChange={this.handleTabChange }
                  items={[
                    "Single Downtime Reason",
                    "Multiple Downtime Reason",
                  ].map((_, i) => {
                    const id = String(i + 1);
                    return {
                      label: `${_}`,
                      key: id,
                      children: (
                        <>
                          {id == 1 ? (
                            <div>
                              {" "}
                              <div
                                hidden={radioStateSingle == "Running"}
                                className="form-group text-center"
                              >
                                <label className="small">Downtime Group</label>

                                <MultiSelect
                                  name="ReasonGroup"
                                  masterName="DownTimeReasonGroup"
                                  objLevel={1}
                                  all={reasonGroupList}
                                  selected={
                                    this.state.selectdeReason["ReasonGroup"]
                                  }
                                  handleOnChange={
                                    this.handleOnChangeReasonGroup
                                  }
                                />
                                {errors.ReasonGroup && (
                                  <p
                                    className="text-danger "
                                    style={{ fontSize: 11 }}
                                  >
                                    {errors.ReasonGroup}
                                  </p>
                                )}
                              </div>
                              <div
                                hidden={radioStateSingle == "Running"}
                                className="form-group text-center"
                              >
                                <label className="small">Downtime Reason</label>

                                <MultiSelect
                                  name="Reason"
                                  masterName="Reason"
                                  objLevel={1}
                                  all={reasonList}
                                  selected={this.state.selectdeReason["Reason"]}
                                  handleOnChange={this.handleOnChangeReason}
                                />

                                {errors.Reason && (
                                  <p
                                    className="text-danger "
                                    style={{ fontSize: 11 }}
                                  >
                                    {errors.Reason}
                                  </p>
                                )}
                              </div>
                              <div className="form-group text-center">
                                <label className="small">Remarks</label>
                                <textarea
                                  rows="2"
                                  cols="65"
                                  maxLength="50"
                                  value={this.state.selectdeReason.Remarks}
                                  placeholder="enter remarks..."
                                  onChange={this.handleOnChangeRemarks}
                                  className="form-control form-control-sm"
                                  name="Remarks"
                                />
                              </div>
                              <div className="form-group text-center ">
                                <label
                                  className="form-check-label small mr-4 "
                                  for="flexCheckChecked"
                                >
                                  End Current Job
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  disabled={this.state.entryType.Multiple}
                                  onChange={this.handleOnChangeRemarks}
                                  name="EndCurrentJob"
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="p-2 border">
                                <div >
                                  <label htmlFor="noOfReasons">
                                    No. of Reasons :
                                  </label>

                                  <Space>
                                    <InputNumber
                                      onKeyDown={(e) => e.preventDefault()} // Prevent key presses
                                      onKeyPress={(e) => e.preventDefault()} // Prevent other keyboard actions
                                      onPaste={(e) => e.preventDefault()} // Prevent pasting into the input
                                      min={2}
                                      max={10}
                                      value={noOfReasons}
                                      onChange={(e) => {
                                        console.log(e, "duhvk");

                                        const reasonsCount = Number(e);
                                        if (reasonsCount > 0) {
                                          // console.log(this.generateSplits(updateEvent[4],updateEvent[5],reasonsCount),"current split data")
                                          this.setState({
                                            Split: this.generateSplits(
                                              updateEvent[4],
                                              updateEvent[5],
                                              reasonsCount - 1,(selectedEquipment && selectedEquipment?.DownTimeThresold ? selectedEquipment.DownTimeThresold : 0)
                                              ,selectedEquipment),
                                            noOfReasons: e,
                                          });
                                        } else {
                                          this.setState({ Split: [] });
                                        }
                                      }}
                                    />
                    
                                  </Space>
                                </div>

                                <label className="mx-2">From :</label>
                                <input
                                  type="datetime-local"
                                  disabled
                                  value={
                                    updateEvent[4]
                                      ? moment(updateEvent[4]).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      : ""
                                  }
                                  name="split-212"
                                />

                                {Split?.map((_, idx) => (
                                  <div className="border" key={idx}>
                                    <div class="container text-center">
                                      <div class="row">
                                        <div class="col-sm-3 p-2">
                                          <div className="row">
                                            <div class="col-sm-9 p-1">
                                              <div className="form-group text-center ">
                                                <label>
                                                  &nbsp;&nbsp;&nbsp;From :{" "}
                                                  {idx + 1}
                                                </label>
                                                <input
                                                  disabled
                                                  value={moment(_.from).format(
                                                    "HH:mm"
                                                  )}
                                                  onChange={(e)=>{this.handleSplitTimeChange(e,idx,'from',{from:moment(updateEvent[4]).format(
                                                    "YYYY-MM-DDTHH:mm"
                                                  ),till:moment(updateEvent[5]).format(
                                                    "YYYY-MM-DDTHH:mm"
                                                  )})}}
                                                  type="time"
                                                  name={`from-${idx + 1}`}
                                                 
                                                />
                                              </div>
                                            </div>
                                            <div class="col-sm-9 p-1">
                                              <div className="form-group text-center ">
                                                <label>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;Till :{" "}
                                                  {idx + 1}
                                                </label>
                                                <input
                                                  min={"08:00"}
                                                  max={"18:00"}
                                                  onChange={(e)=>{this.handleSplitTimeChange(e,idx,'till',{from:moment(updateEvent[4]).format(
                                                    "YYYY-MM-DDTHH:mm"
                                                  ),till:moment(updateEvent[5]).format(
                                                    "YYYY-MM-DDTHH:mm"
                                                  )})}}

                                                  disabled={
                                                    idx == Split.length - 1
                                                      ? true
                                                      : false
                                                  }
                                                  value={moment(_.till).format(
                                                    "HH:mm"
                                                  )}
                                                  type="time"
                                                  name={`till-${idx + 1}`}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-9">
                                          <div class="row">
                                            <div class="col-8 col-sm-6">
                                              <div
                                                hidden={
                                                  radioStateSingle == "Running"
                                                }
                                                className="form-group text-center "
                                              >
                                                <label className="small">
                                                  Downtime Group
                                                </label>

                                                <MultiSelect
                                                  name="ReasonGroup"
                                                  masterName="DownTimeReasonGroup"
                                                  objLevel={1}
                                                  all={reasonGroupList}
                                                  selected={
                                                  (reasonSplitGroupList &&  reasonSplitGroupList[idx] && reasonSplitGroupList[idx].selectdeReason
                                                    [
                                                      "ReasonGroup"] )?? ''
                                                  }
                                                  handleOnChange={(e)=>
                                                    this.handleOnChangeReasonGroup(e,'Split',idx)
                                                  }
                                                />
                                                {console.log(reasonSplitGroupList,"dwiudui")}
                                                {errors.ReasonGroup && (
                                                  <p
                                                    className="text-danger "
                                                    style={{ fontSize: 11 }}
                                                  >
                                                    {errors.ReasonGroup}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                            <div class="col-4 col-sm-6">
                                              <div
                                                hidden={
                                                  radioStateSingle == "Running"
                                                }
                                                className="form-group text-center "
                                              >
                                                <label className="small">
                                                  Downtime Reason
                                                </label>

                                                <MultiSelect
                                                  name="Reason"
                                                  masterName="Reason"
                                                  objLevel={1}
                                                  all={(reasonSplitGroupList && reasonSplitGroupList[idx] && reasonSplitGroupList[idx].ReasonList) ? reasonSplitGroupList[idx].ReasonList:[]}
                                                  selected={
                                                    (_ && _?.DowntimeReason && _.DowntimeReason[
                                                      "Reason"
] )?? ''
                                                  }
                                                  handleOnChange={
                                                    (e)=>this.handleOnChangeReason(e,"Split",idx)
                                                  }
                                                />

                                                {errors.Reason && (
                                                  <p
                                                    className="text-danger "
                                                    style={{ fontSize: 11 }}
                                                  >
                                                    {errors.Reason}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                            <div class="col-4 col-sm-6">
                                              <div className="form-group text-center">
                                                <label className="small">
                                                  Remarks
                                                </label>
                                                <textarea
                                                  rows="2"
                                                  cols="65"
                                                  maxLength="50"
                                                  value={
                                                    (_ && _?.DowntimeReason && _.DowntimeReason["Remarks"] )?? ''}
                                                
                                                  placeholder="enter remarks..."
                                                  onChange={(e)=>
                                                    this.handleOnChangeRemarks(e,"Split",idx)
                                                  }
                                                  className="form-control form-control-sm"
                                                  name="Remarks"
                                                />
                                              </div>
                                            </div>
                                            <div class="col-4 col-sm-6">
                                              <div className="form-group text-center d-flex p-3">
                                                <label
                                                  className="form-check-label small  "
                                                  for="flexCheckChecked"
                                                >
                                                  End Current Job
                                                </label>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={ (_ && _?.EndCurrentJob && _.EndCurrentJob)?_.EndCurrentJob : false}
                                                  disabled={
                                                    this.state.entryType
                                                      .Multiple
                                                  }
                                                  onChange={(e)=>this.handleOnChangeRemarks(e,"Split",idx)
                                                  }
                                                  name="EndCurrentJob"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                            
                                <label className="m-2">Till :</label>
                                <input
                                  type="datetime-local"
                                  disabled
                                  value={
                                    updateEvent[5]
                                      ? moment(updateEvent[5]).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      : ""
                                  }
                                  name="split-212"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ),
                    };
                  })}
                />}
              </> */}
            {/* ) */}
            {/* } */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleReasonUpdate}>
              Update Reason
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="row mx-2">
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">DateTime</label>
              <input
                className="form-control form-control-sm"
                defaultValue={dateTime.getDateFormatForInputTypeHtml(
                  new Date(Date.now())
                )}
                onChange={this.handleOnChange}
                type="date"
                name="DateTime"
              />
              {errors.DateTime && (
                <p className="text-danger " style={{ fontSize: 11 }}>
                  {errors.DateTime}
                </p>
              )}
            </div>
          </div>

          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">Shift</label>
              <select
                className="form-control form-control-sm"
                onChange={this.handleOnChange}
                name="Shift"
              >
                <option>--select shift--</option>
                {shiftSetting.map((one) => {
                  return (
                    <option value={one.Shift}>
                      {one.Shift}({dateTime.getTime(one.ShiftStart)}-
                      {dateTime.getTime(one.ShiftEnd)})
                    </option>
                  );
                })}
              </select>
              {errors.Shift && (
                <p className="text-danger " style={{ fontSize: 11 }}>
                  {errors.Shift}
                </p>
              )}
            </div>
          </div>

          <div className="col-sm-1">
            <div className="form-group text-center">
              <button
                className="btn btn-md btn-primary mt-4"
                disabled={
                  !getRightsForAccess("DynamicDataRights", "Downtime", "read")
                }
                onClick={() => this.handleClickOK(this.state.inputData)}
              >
                OK
              </button>
            </div>
          </div>

          <div className="col-sm-2  mt-4">
            <input
              type="radio"
              name="EntryType"
              value="Single"
              disabled={this.state.machineStates.length == 0}
              id="Single"
              checked={
                this.state.entryType.Single ||
                this.state.entryType.Single == "true"
              }
              onChange={this.handleOnChange}
            />
            &nbsp;<label htmlFor="yes">Single</label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              name="EntryType"
              id="Multiple"
              disabled={this.state.machineStates.length == 0}
              value="Multiple"
              checked={
                this.state.entryType.Multiple ||
                this.state.entryType.Multiple == "true"
              }
              onChange={this.handleOnChange}
            />
            &nbsp;<label htmlFor="no">Multiple</label>
          </div>
          <div className="col-sm-1  mt-4">
            <input
              type="checkbox"
              name="JobView"
              onChange={this.handleOnJoView}
            />
            &nbsp;<label htmlFor="yes">Job</label>&nbsp;
          </div>
          <div className="col-sm-1 ">
            <div className="form-group text-center">
              <button
                className="btn btn-sm btn-primary mt-4"
                onClick={this.handleClickAddNew}
              >
                Job&nbsp;&nbsp;
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
          <div className="col-sm-1 ">
            <div className="btn-group mt-4">
              <button
                className="btn btn-primary btn-sm dropdown-toggle dropbtn-btn-filter-group"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Equipment Group
                <FontAwesomeIcon icon={faFilter} />
              </button>

              <div className="dropdown-menu">
                {/* <div className="dropdown-item">
                                <input onChange={this.handleFilterBy} checked={this.state.filterBy['All']} name="All" type="checkbox" />
                                <sapn className="ml-2 p-0">All</sapn>
                              </div> */}
                {this.state.equipmentGroupList.map((one) => {
                  return (
                    <div className="dropdown-item">
                      <input
                        type="checkbox"
                        name={one.name}
                        checked={one.enable}
                        onChange={this.handleOnChangeFilter}
                      />
                      <sapn className="ml-2 p-0">{one.name}</sapn>
                    </div>
                  );
                })}
              </div>
              {/* <Dropdown



                className="mt-4 " >
                <Dropdown.Toggle>
                  Equipment Group
                  <FontAwesomeIcon icon={faFilter} />
                </Dropdown.Toggle>

                <Dropdown.Menu>

                  {this.state.equipmentGroupList.map((one) => {
                    return <Dropdown.Item>
                      <input
                        type="checkbox"
                        name={one.name}
                        checked={one.enable}
                        onChange={this.handleOnChangeFilter}
                      />
                      <span className="pl-2">{one.name}</span>
                    </Dropdown.Item>
                  })}

                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </div>

          {this.state.entryType.Multiple ? (
            <div className="col-sm-1">
              <div className="form-group text-center">
                <button
                  className="btn btn-md btn-primary mt-4"
                  disabled={this.state.machineStates.length == 0}
                  onClick={() =>{
                    this.setState({Entrytype:'Normal'})
                    this.handleOnUpdateMultiple(this.state.updateEventMultiple)}
                  }
                >
                  Update Multiple
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          {this.state.entryType.Multiple ? (
            <div className="col-sm-1">
              <div className="form-group text-center">
                <button
                  className="btn btn-md btn-primary mt-4"
                  disabled={this.state.machineStates.length == 0}
                  onClick={this.handleReset}
                >
                  Reset
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-12 ">
          {machineStates.length > 0 ? (
            !querySuccess ? (
              <MachineStateChart
                setFilterView={this.setFilterView}
                filterView={this.state.equipFilter}
                equipGroups={this.state.equipmentGroupList}
                jobView={this.state.jobView}
                chartEventClickNo={this.state.chartEventClickNo}
                data={machineStates}
                getSystemState={this.getSystemState}
                updateEventMultiple={this.state.updateEventMultiple}
                systemGenerated={this.state.systemGenerated}
                entryType={this.state.entryType}
                id="Downtime"
                handleChartEvent={this.handleChartEvent}
                reportType="History"
                hidehead={true}
              />
            ) : (
              <p>Loading...</p>
            )
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DowntimeView;
