import React, { useState } from 'react';
import Table from '../Common/table';
import ColumnChart from '../Charts/ColumnChart';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import { ToastContainer, toast } from 'react-toastify';

import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import DateSelection from '../Common/DateSelection';
import Apexbarchart from '../Charts/ApexBarchart';
import moment from 'moment';
const DayWiseOEEReport = ({ user, equips,equipGroups,fromDateLimit }) => {
  const [availabilityData, setAvailabilityData] = useState([])
  const [inputData, setInputData] = useState({})
  const [errors, setErrors] = useState({})
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [querySuccess, setQuerySuccess] = useState(false)
  const [index, setIndex] = useState(0);
  const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })


const getEquipsForReport=()=>{
    let equipArr=[]
    equips.forEach((one)=>{
        selectedEquipOption.forEach((option)=>{
            if(one._id==option.value){
                equipArr.push(one);
            }
        })
    })
    return equipArr;
}
  const schema = {
    From: joi.date().required().label('From'),
    Till: joi.date().required().label('Till'),
    UserId: joi.string(),
    Periodicity : joi.string(),
  }
  const columns = [
    { path: 'ShiftOf', label: 'Date', content: (obj) => <label>{obj.ShiftOf && date.getDateFormat(obj.ShiftOf)}</label> },
    // { path: 'Shift', label: 'Shift' },
    // { path: 'Equipment', label: 'Equipment' },
    // { path: 'TotalTime', label: 'TotalTime (mins)' },
    { path: 'OEE', label: 'OEE (%)' , content: (obj) => <label>{(isNaN(parseFloat(obj.OEE)) ? "0" : parseFloat(obj.OEE).toFixed(1))}
</label>},
    // { path: 'Performance', label: 'Performance (%)', content: (obj) => <label>{(Number(obj.Performance)).toFixed(1)}</label> },
    // { path: 'QualityFactor', label: 'Quality (%)' ,content: (obj) => <label>{(Number(obj.QualityFactor)).toFixed(1)}</label> },
  ]

const handelOrganiseChartdata =(data)=>{
  
  const organisedData =data.map(one=>({Date:moment(one.ShiftOf).format('DD/MM/YYYY') , OEE: one.OEE,Periodicity: one.Periodicity}))
  console.log(organisedData,'kfnvdkfvm')
 return organisedData
}
  const handleOnSubmit = async (inputData) => {
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId = user._id

  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }

     inputData.Periodicity = "Day"
 
      
      try {
        let result = await axios.post(api.api + 'DayWiseOEE', inputData)
        if (result.data.length > 0) {
          setAvailabilityData(result.data);
        }else{
          swal('No Data Available')
        }
      }
      catch (err) {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        setQuerySuccess(false)
      }

    
    setQuerySuccess(false)

  }
  const handleDonwLoad = (e, date) => {
    generateExcelFile(e, date)
  }

  const handleSort = (sortColumn) => {
    if (availabilityData.length > 0) {
      const sorted = _.orderBy(
        availabilityData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setAvailabilityData(sorted)
      setSortColumn(sortColumn)
    }
  }


  const SelectedDates =(dates)=>{
    setInputData(dates)
  }

  return (
    <React.Fragment>
      {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
     
      <div className="text-center heading-bg mb-2">
        <h4 className="text-white p-0 m-0" >
        Day Wise OEE
        </h4>
      </div>
      <ToastContainer/>
      <div className="row">
      {/* {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>} */}
      <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
       
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
         
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
          
        </div> */}
        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
        {availabilityData.length > 0 ? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDonwLoad('DayWiseOEE', inputData.DateTime)}>Download Excel</button>
        </div> : ''}
      </div>
      <div>
        {availabilityData.length > 0 ?
          <>
          <div style={{backgroundColor:'grey',width:'100%',height:'500px'}} className="">

          <Apexbarchart
              jsonData={handelOrganiseChartdata(availabilityData)}
              title="Day Wise OEE (%)"
              header="Date"
              value="OEE"
              chartType="bar"
              height="100%"
              orientation="vertical"
              barColor={"#ff8836d9"}
              // textColor="black"
              />
              </div>
            {/* <p className='small ml-2'>* Availability Calculated Formula ((RunTime/(TotalTime - Breaks - PlannedDowntime))*100)%</p> */}
            <Table id='DayWiseOEE' onSort={handleSort} columns={columns} sortColumn={sortColumn} data={availabilityData} />
          </> : ''}
      </div>
    </React.Fragment>


  );
}

export default DayWiseOEEReport;