import React, { useState, useEffect } from "react";
import Table from "../Common/table";
import axios from "../httpServices/httpService";
import api from "../config";
import swal from "sweetalert";
import auth from "../httpServices/authService";
import validation from "../utill/validation";
import _, { filter } from "lodash";
import joi from "joi";
import Chart from "react-google-charts";
import Loading from "./../utill/LoadingComponent";
import date from "../utill/dateTime";
import { generateExcelFileFull, generateExcelFile } from "../utill/download";
import BarChart from "react-google-charts";
import dateTime from "../utill/dateTime";
import ScatterChart from "../utill/scatterChart";
import { ToastContainer, toast } from "react-toastify";

const MachineActvity = ({ user, fromDateLimit, storeDataInLocalStorage }) => {
  const [equipProdData, setEquipProdData] = useState([
    [
      "Time(In Minutes)",
      "Production Count",
      { role: "style" },
      { role: "annotation" },
    ],
  ]);
  const [equipJobsData, setEquipJobsData] = useState([]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [shiftSetting, setShiftSetting] = useState([]);
  const [equips, setEquips] = useState([]);
  const [selectedEquip, setSelectedEquip] = useState({});
  const [cycleDurationData, setCycleDurationData] = useState({});
  const [componentList, setComponentList] = useState([]);
  const [allData, setAllData] = useState({})
  const [POwerDownEvent, setPOwerDownEvent] = useState(false);


  const [PowerData, setPowerData] = useState([
    "Time(In Minutes)",
    "Production Count",
    { role: "style" },
    { role: "annotation" },
    'Device Status'
  ],);

  // console.log(PowerData,"cehvbhfhf",equipProdData)
  // useEffect(() => {
  //     axios.get(api.api1 + 'component')
  //         .then((result) => {
  //             setComponentList(result.data);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         })
  // }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const result = await axios.get(api.api1 + 'component');
        let result = await storeDataInLocalStorage("component");

        setComponentList(result);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Set today's date as the default value

    let preSelectedDate = getTodayDate();
    handleOnChange({
      currentTarget: { type: "date", name: "DateTime", value: preSelectedDate },
    });
    // setInputData(preSelectedDate);
  }, []);

  const convertDateFormat = (dateString) => {
    const [month, day, year] = dateString?.split("-");
    return `${month}-${day}-${year}`;
  };
  const getTodayDate = () => {
    const today = new Date();
    let month = String(today.getMonth() + 1).padStart(2, "0");
    let day = String(today.getDate()).padStart(2, "0");
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  };
  const schema = {
    DateTime: joi.date().required().label("Datetime"),
    UserId: joi.string(),
    Shift: joi.string().required().label("Shift"),
    Equipment: joi.string().required().label("Equipment"),
    Type: joi.string(),
    EquipType: joi.string(),
    DownTimeThresold: joi.number(),
  };

  const columns = [
    {
      path: "Date",
      label: "Date",
      content: (obj) => <label>{dateTime.getDateFormat(obj.From)}</label>,
    },
    { path: "Shift", label: "Shift" },
    {
      path: "From",
      label: "From",
      content: (obj) => <label>{dateTime.getTime(obj.From)}</label>,
    },
    {
      path: "Till",
      label: "Till",
      content: (obj) => <label>{dateTime.getTime(obj.Till)}</label>,
    },
    {
      key: "ComponentAlias",
      label: "Component Alias",
      content: (obj) => {
        let component = [...componentList];
        component = component.filter((one) => one.ComponentId == obj.Component);
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>;
        } else {
          return <label>N/A</label>;
        }
      },
    },
    { path: "Component", label: "Component" },
    { path: "OperationID", label: "Operation" },
    {
      path: "ToolID",
      label: "Tool",
      content: (obj) => (
        <label>
          {obj.ToolID == "null" || obj.ToolID == null ? "-" : obj.ToolID}
        </label>
      ),
    },
  ];

  const handleOnChange = async (e) => {
    if (e.currentTarget.type === "date") {
      inputData[e.currentTarget.name] = convertDateFormat(
        e.currentTarget.value
      );
      setQuerySuccess(true);
      let equip = await axios.get(api.api1 + "equipment");
      let res = await axios.put(api.api + "GetShiftSettingForDateShift", {
        DateTime: inputData.DateTime,
      });
      setShiftSetting(res.data);
      setEquips(equip.data);
      setQuerySuccess(false);
    } else if (e.currentTarget.name == "Equipment") {
      setSelectedEquip(
        equips.filter((one) => one.EquipmentID == e.currentTarget.value)[0]
      );
      inputData[e.currentTarget.name] = e.currentTarget.value;
    } else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
    }

    setInputData(inputData);
    setErrors({});
  };

  const getCycleDurationData = (data) => {
    let schema = ["Time", "Cycle Duration", "Interval Duration"];
    let arr = [schema];
    data.forEach((one, index) => {
      let dataArr = [];
      if (one.EventType == "EOP" && data[index + 1]) {
        if (data[index + 1].DocumentType == "Production") {
          let timeDur =
            (new Date(data[index + 1].DateTime).getTime() -
              new Date(one.DateTime).getTime()) /
            1000;
          if (timeDur < 0) {
            //     console.log(timeDur)
          }
          dataArr.push(new Date(data[index + 1].DateTime));
          dataArr.push(timeDur);
          dataArr.push(0);
          arr.push(dataArr);
        }
      }
    });
    let filterArr = data.filter((one) => one.EventType == "SOP");
    try {
      filterArr.forEach((obj, index) => {
        if (filterArr[index + 1]) {
          let timeDur =
            (new Date(filterArr[index + 1].DateTime).getTime() -
              new Date(obj.DateTime).getTime()) /
            1000;
          if (arr[index + 1]) arr[index + 1][2] = timeDur;
        }
      });
    } catch (e) {
      // console.log(e)
    }

    return arr.length > 1 ? arr : [];
  };
  const handleOnSubmit = (inputData) => {
    const errors = validation.validate(inputData, schema);
    //console.log(errors)
    setErrors(errors || {});
    console.log(inputData, "bdfbdfbbgd", errors)
    if (errors) return;
    setQuerySuccess(true);
    inputData.UserId = user._id;
    inputData.Type = "History";
    inputData.EquipType = selectedEquip.DeviceConfig;
    inputData.DownTimeThresold = selectedEquip.DownTimeThresold
    axios
      .put(api.api + "GetCardViewDashboardForLiveViewForEquipment", inputData)
      .then((result) => {
        // console.log(result.data, "CardViewDashboard");
        if (result.data !== null && result.data !== undefined) {
          let singleMacData;
          let singleMacData1 = [
            [
              "Time(In Minutes)",
              "Device Power",
              { role: "style" },
              { role: "annotation" },
              // "Device Status",
              // {role:"annotation"},

            ],
          ];
          if (selectedEquip.DeviceConfig == "ProductionEvent") {
            singleMacData = [
              [
                "Time(In Minutes)",
                "Production Count",
                { role: "style" },
                { role: "annotation" },
                "Time Diffrence (sec)",
                { role: "style" },

              ],
            ];
          } else {
            singleMacData = [
              [
                "Time(In Minutes)",
                "Production Count",
                { role: "style" },
                { role: "annotation" },
              ],
            ];
          }
          if (result.data && result.data.result.length > 0) {

            result.data.result.forEach((element) => {
              let arr = [];

              ////  For Power Down Power Up Code
              let arr1 = [];
              if (element.DocumentType === "Power") {
                arr1.push(new Date(element.DateTime))
                arr1.push(1);
                if (element.Event === 'PowerUp') {

                  arr1.push("#008000");

                } else {

                  arr1.push("red");
                }
                arr1.push(element.Event);
              } else {
                arr1.push(new Date(element.DateTime))
                arr1.push(0)
                arr1.push("#007bff")
                arr1.push("")
              }
              ////  For Power Down Power Up Code


              arr.push(new Date(element.DateTime));
              if (selectedEquip.DeviceConfig == "ProductionCounter") {
                arr.push(element.Count);
                arr.push("#008000");
                arr.push("");
              }

              if (selectedEquip.DeviceConfig == "ProductionEvent") {
                arr.push(1);
                arr.push("#008000");
                arr.push('');
                arr.push(Number(element.nextDiff));
                arr.push('blue');



              }
              if (
                selectedEquip.DeviceConfig == "ProductionLogger" ||
                selectedEquip.DeviceConfig == "ProductionCounterLogger"
              ) {
                arr.push(1);
                if (element.EventType == "EOP") {
                  arr.push("yellow");
                  arr.push(element.EventType);
                } else if (element.EventType == "SOP") {
                  arr.push("green");
                  arr.push(element.EventType);
                } else {
                  arr.push("red");
                  arr.push(element.Event);
                }
              }

              singleMacData.push(arr);
              singleMacData1.push(arr1);

            });
          if(result.data.length > 0){
            setAllData(result.data)

          }else{
            setAllData([])

          }
            setCycleDurationData(getCycleDurationData(result.data.result));
            setEquipProdData(singleMacData);
            setPowerData(singleMacData1);

            setQuerySuccess(false);
          } else {
            setAllData([])
            setEquipProdData([]);
            setPowerData([]);
            setCycleDurationData([])
            swal("Production Data Not Available");
            setQuerySuccess(false);
          }
        } else {
          setQuerySuccess(false);
        }
      })
      .catch((err) => {
        toast.success(`${err}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: "red", // Set your desired background color
            color: "white", // Set your desired text color
          },
        });
        //  console.log(err)
        setQuerySuccess(false);
      });

    setQuerySuccess(true);
    axios
      .post(api.api1 + "JobRunsReportForEquipmentForMachineActvity", inputData)
      .then((result) => {
        //   console.log(result)
        if (result.data !== null && result.data !== undefined) {
          if (result.data.length > 0) {
            setEquipJobsData(result.data);
            setQuerySuccess(false);
          } else {
            setEquipJobsData([]);
            swal("Job Entry Data Not Available");
            setQuerySuccess(false);
          }
        } else {
          setQuerySuccess(false);
        }
      })
      .catch((err) => {
        toast.success(`${err}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: "red", // Set your desired background color
            color: "white", // Set your desired text color
          },
        });
        //    console.log(err)
        setQuerySuccess(false);
      });
  };

  const handleDownload = (e, date) => {
    generateExcelFile(e, date);
  };

  const chartRender = (data, title) => {
    return (
      <BarChart
        chartType="ComboChart"
        width="100%"
        height="400px"
        data={data}
        loader={<div>Loading Chart</div>}
        options={{
          vAxes: {
            // Define properties for both vertical axes
            0: {
              title: title,
              format: "#",
              minValue: 0,
              maxValue: 2,
            },
            1: {
              title: "Time Diffrence (sec)",
              format: "#",
              minValue: 0,
              maxValue: 8,
            },
          },
          hAxis: {
            slantedText: true,
            slantedTextAngle: 90,
            textStyle: { fontSize: 9 },
            title: "Time", // This title is for the horizontal axis
          },

          tooltip: { isHtml: true },
          animation: { startup: true, duration: 400, easing: "out" },
          explorer: {
            maxZoomIn: 10,
            maxZoomOut: 8,
            zoomDelta: 2,
            actions: ["dragToZoom", "rightClickToReset"],
            axis: "horizontal",
            // axis: "vertical",
            // keepInBounds: true,
            // maxZoomIn: 4.0
          },
          seriesType: "bars",
          series: {
            0: { targetAxisIndex: 0 }, // Use axis 0 for the first series (bars)
            1: { targetAxisIndex: 1, type: "line" }, // Use axis 1 for the second series (line)
          },
          annotations: {
            style: 'line', // or 'point', or 'text', or 'arrow'
            textStyle: {
              fontSize: 10,
              fontName: 'Arial',
            },
            stemColor: 'none', // No stem
            position: 'vertical',
            stem: {
              color: 'transparent',
              length: '50%', // Set the stem length to 50% to position it in the middle of the bars
            },
            // Align annotations vertically
          },
          // annotations: {
          //   textStyle: { fontSize: 10, color: "black" },
          //   datum: {
          //     stem: { color: "transparent" },
          //     textPosition: "out",
          //   },
          //   highContrast: true,
          // },
          legend: { position: "none" },
        }}
      />
    );
  };

  const scatterChart = (data) => {
    return <ScatterChart data={data} id="activity" />;
  };


  const show_Chart_Diff = (diffType) => {
    if (selectedEquip.DeviceConfig == "ProductionCounterLogger" || selectedEquip.DeviceConfig == "ProductionLogger") {
      const lineChart = [["Time(In Minutes)", "Time Diffrence", { role: "style" }]]
      const histogramChart = [["Time(In Seconds)", "No Of Incident", { role: "style" }, { role: "tooltip", type: "string" }]]
      allData[diffType] && allData[diffType].Line.forEach((x, idx) => {
        const arr = [];
        arr.push(new Date(x.DateTime));
        arr.push(x.Value)
        arr.push("orange");
        lineChart.push(arr);
      })
      allData[diffType] && allData[diffType].Histogram.forEach((x, idx) => {
        const arr = [];
        arr.push(x.Value);
        arr.push(x.Count);
        arr.push("orange");
        arr.push(`Time (In Sec): ${x.Value} \n No Of Incident: ${x.Count}`)
        histogramChart.push(arr);
      })
      return <>
        {lineChart.length >1 && <BarChart
          chartType="LineChart"
          width="100%"
          height="400px"
          data={lineChart}
          options={{
            vAxes: {
              0: { title: "Time Difference (sec)", format: "#", minValue: 0 },
            },
            hAxis: {
              slantedText: true,
              slantedTextAngle: 45, // More readable angle
              textStyle: { fontSize: 10 }, // Slightly increased font size
              title: "Time", // Title for the horizontal axis
            },
            tooltip: {
              isHtml: true,
              textStyle: { fontSize: 12 },
            },
            animation: { startup: true, duration: 400, easing: "out" },
            explorer: {
              maxZoomIn: 10,
              maxZoomOut: 8,
              zoomDelta: 2,
              actions: ["dragToZoom", "rightClickToReset"],
              axis: "horizontal",
              keepInBounds: true, // Prevents going beyond the data range
            },
            series: {
              0: { targetAxisIndex: 0, type: "line" },
            },
            colors: ["orange", "#FF00FF", "#0000FF"],
            annotations: {
              textStyle: { fontSize: 10, color: "black" },
              datum: {
                stem: { color: "transparent" },
                textPosition: "out",
              },
              highContrast: true,
            },
            legend: { position: "none" },
          }}
        />}
       {histogramChart.length >1 && <BarChart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={histogramChart}
          options={{
            title: selectedEquip.DeviceConfig === 'ProductionCounter'
              ? "SPM / Cycles Per Minute Histogram"
              : "Time (In Sec) / No Of Incident  Histogram",
            vAxes: {
              0: {
                title: selectedEquip.DeviceConfig === 'ProductionCounter'
                  ? "Number Of Minutes"
                  : "No of Incident",
                format: "#",
                minValue: 0
              },
            },
            hAxis: {
              slantedText: true,
              slantedTextAngle: 45, // More readable angle
              textStyle: { fontSize: 10 }, // Slightly increased font size
              title: selectedEquip.DeviceConfig === 'ProductionCounter'
                ? "Cycles Per Minute / SPM"
                : "Time (In Sec)", // Title for the horizontal axis
            },
            tooltip: {
              isHtml: true, // Use custom HTML tooltips
            },
            animation: {
              startup: true,
              duration: 400,
              easing: "out"
            },
            explorer: {
              maxZoomIn: 10,
              maxZoomOut: 8,
              zoomDelta: 2,
              actions: ["dragToZoom", "rightClickToReset"],
              axis: "horizontal",
              keepInBounds: true, // Prevents going beyond the data range
            },
            annotations: {
              textStyle: { fontSize: 10, color: "black" },
              datum: {
                stem: { color: "transparent" },
                textPosition: "out",
              },
              highContrast: true,
            },
            legend: { position: "none" },
          }}
        />}


      </>
    } else {
      const histogramChart = [["Cycle Per Minute", "No Of Minutes", { role: "style" }, { role: "tooltip", type: "string" }]]
      allData[diffType] && allData[diffType].Histogram.forEach((x, idx) => {
        const arr = [];
        arr.push(x.Value);
        arr.push(x.Count);
        arr.push("orange");
        arr.push(`Cycle Per Minute: ${x.Value} \n No Of Minutes: ${x.Count}`)
        histogramChart.push(arr);
      })
      return <BarChart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={histogramChart}
        options={{
          title: selectedEquip.DeviceConfig === 'ProductionCounter'
            ? "SPM / Cycles Per Minute Histogram"
            : "Time (In Sec) / No Of Incident  Histogram",
          vAxes: {
            0: {
              title: selectedEquip.DeviceConfig === 'ProductionCounter'
                ? "Number Of Minutes"
                : "No of Incident",
              format: "#",
              minValue: 0
            },
          },
          hAxis: {
            slantedText: true,
            slantedTextAngle: 45, // More readable angle
            textStyle: { fontSize: 10 }, // Slightly increased font size
            title: selectedEquip.DeviceConfig === 'ProductionCounter'
              ? "Cycles Per Minute / SPM"
              : "Time (In Sec)", // Title for the horizontal axis
          },
          tooltip: {
            isHtml: true, // Use custom HTML tooltips
          },
          animation: {
            startup: true,
            duration: 400,
            easing: "out"
          },
          explorer: {
            maxZoomIn: 10,
            maxZoomOut: 8,
            zoomDelta: 2,
            actions: ["dragToZoom", "rightClickToReset"],
            axis: "horizontal",
            keepInBounds: true, // Prevents going beyond the data range
          },
          annotations: {
            textStyle: { fontSize: 10, color: "black" },
            datum: {
              stem: { color: "transparent" },
              textPosition: "out",
            },
            highContrast: true,
          },
          legend: { position: "none" },
        }}
      />
    }
  }
  return (
    <React.Fragment>
      {" "}
      <ToastContainer />
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Machine Activity
        </h4>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Date</label>
            <input
              className={"form-control form-control-sm"}
              placeholder="dd/mm/yyyy"
              min={fromDateLimit}
              type="date"
              value={inputData.DateTime}
              onChange={handleOnChange}
              name="DateTime"
            />
            {errors.DateTime && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.DateTime}
              </p>
            )}
          </div>
        </div>
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
        </div> */}
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Shift</label>
            <select
              className="form-control form-control-sm"
              onChange={handleOnChange}
              name="Shift"
            >
              <option>--select shift--</option>
              {shiftSetting.map((one) => {
                return (
                  <option value={one.Shift}>
                    {one.Shift}({date.getTime(one.ShiftStart)}-
                    {date.getTime(one.ShiftEnd)})
                  </option>
                );
              })}
            </select>
            {errors.Shift && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.Shift}
              </p>
            )}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Equipment</label>
            <select
              className="form-control form-control-sm"
              onChange={handleOnChange}
              name="Equipment"
            >
              <option>--select equipment--</option>
              {equips.map((one) => {
                return (
                  <option value={one.EquipmentID}>{one.EquipmentID}</option>
                );
              })}
            </select>
            {errors.Equipment && (
              <p className="text-danger " style={{ fontSize: 11 }}>
                {errors.Equipment}
              </p>
            )}
          </div>
        </div>

        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => { handleOnSubmit(inputData); setPOwerDownEvent(false) }}
          >
            Apply
          </button>
        </div>
        <div className="col-sm-2">
          <button
            disabled={equipJobsData.length > 1 ? false : true}
            className="btn btn-primary btn-sm mt-4"
            onClick={() =>
              handleDownload("MachineActvityJob", inputData.DateTime)
            }
          >
            Download Excel
          </button>
        </div>
      </div>
      <div>
        {equipProdData.length > 1 ? (
          chartRender(equipProdData, " Cycles Count")
        ) : querySuccess ? (
          <p>Loading....</p>
        ) : (
          ""
        )}
      </div>
      <div>
        {PowerData[1] && PowerData[1].length > 1 ? (
          chartRender(PowerData, 'Device power')
        ) : querySuccess ? (
          <p>Loading....</p>
        ) : (
          ""
        )}

      </div>
      <div>
        {cycleDurationData.length > 0 ? (
          scatterChart(cycleDurationData)
        ) : querySuccess ? (
          <p>Loading....</p>
        ) : (
          ""
        )}
      </div>
      {selectedEquip && ((selectedEquip.DeviceConfig === "ProductionCounter") || (selectedEquip.DeviceConfig === "ProductionEvent")) &&
        <div>{show_Chart_Diff("SPM")}</div>}
      {selectedEquip && ((selectedEquip.DeviceConfig === "ProductionCounterLogger") || (selectedEquip.DeviceConfig === "ProductionLogger")) && <div className="border text-center">
        <strong >SOP - EOP ( Process Time )</strong>
        {show_Chart_Diff("SOP_EOP_Diffrence")}
      </div>}
      {selectedEquip && ((selectedEquip.DeviceConfig === "ProductionCounterLogger") || (selectedEquip.DeviceConfig === "ProductionLogger")) && <div className="border text-center">
        <strong >EOP - SOP ( Prepration Time )</strong>
        {show_Chart_Diff("EOP_SOP_Diffrence")}
      </div>}
      {selectedEquip && ((selectedEquip.DeviceConfig === "ProductionCounterLogger") || (selectedEquip.DeviceConfig === "ProductionLogger")) && <div className="border text-center" >
        <strong >SOP - SOP ( Cycle Time )</strong>
        {show_Chart_Diff("SOP_SOP_Diffrence")}
      </div>}
      <div className="mt-5">
        {equipJobsData.length > 0 ? (
          <Table
            id="MachineActvityJob"
            columns={columns}
            sortColumn
            data={equipJobsData}
          />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};
export default MachineActvity;
