import { Select } from "antd";
import Pako from "pako";
import React, { useEffect, useState } from "react";
import Apexbarchart from "../../Charts/ApexBarchart";
import api from "../../config.json";
import BatchSizeConfig from "../../config.json";
import axios from "../../httpServices/httpService";
import swal from "sweetalert";

const PressShopMonitoring = ({ user }) => {
  const [dashBoardData, setDashBoardData] = useState([]);
  const [operatordashBoardData, setoperatordashBoardData] = useState([]);
  const [downTimedashBoardData, setdownTimedashBoardData] = useState([]);

    const fetchData1 = async () => {
      const batchSize = Number(BatchSizeConfig.BatchSize);
      let DowntimeArry =[]; 
      // Retrieve equipment data from localStorage
      const storedEquipGroupsData = localStorage.getItem("equipment");
      let equips = [];
  
      if (storedEquipGroupsData) {
        equips = JSON.parse(storedEquipGroupsData);
      }
       let dataArray = [];
      if (equips && equips.length > 0) {
        for (let i = 0; i < Math.ceil(equips.length / batchSize); i++) {
          const startIndex = i * batchSize;
          const endIndex = (i + 1) * batchSize;
          const sendData = equips.slice(startIndex, endIndex);
  
          // Compress data using Pako
          const compressedData = Pako.gzip(
            JSON.stringify({ UserId: user._id, Equips: sendData })
          );
  
          try {
            // Send compressed data via API
            const result = await axios.put(`${api.api1}GetAndonViewDashboard`, compressedData,
              {
                headers: {
                  "Content-Encoding": "gzip", // Specify gzip encoding
                  "Content-Type": "application/json", // Set content type to JSON
                },
              }
            );
            console.log(DowntimeArry,"godijgldfgldfgdfhg")
            const result1 = await axios.put(`${api.api1}PressShopMonitoringDashboard`,  {UserId: user._id, Equips: sendData })
            if(result1.data && result1.data.length > 0) {
              result1.data.forEach((element) => {
                element.Event.forEach((item) => {
                  const reason = item.DowntimeReason ?? 'Down';
                  const duration = Math.round(parseFloat(item.Duration) / 60) ?? 0;
                  const color = element.Color
                  // Check if the DowntimeReason already exists in DowntimeArry
                  const existingEntry = DowntimeArry.find(
                    (entry) => entry.DowntimeReason === reason
                  );
              
                  if (existingEntry) {
                    // If it exists, add the duration to the existing entry
                    existingEntry.Duration += duration;
                  } else {
                    // If it doesn't exist, add a new entry
                    DowntimeArry.push({ DowntimeReason: reason, Duration: duration ,color});
                  }
                });
              });
              
            }
            // Decompress received data
            const decompressedData = Pako.ungzip(result.data, { to: "string" });
  
            // Convert decompressed string to JSON
            const jsonData = JSON.parse(decompressedData);
            setDashBoardData((prevData) => [...prevData, ...jsonData]);


            console.log(equips, "Current equipments found", jsonData);
          } catch (err) {
            // Handle errors
            if (
              err.response &&
              err.response.data &&
              err.response.data.code === 901
            ) {
              swal(`${err.response.data.message}`);
            } else {
              swal(`${err}`);
            }
          }
        }
        setdownTimedashBoardData(DowntimeArry);

      }
    };
    
    const fetchData2 = async () => {
      try {
        const result1 = await axios.put(`${api.api1}OperatorPerformanceForPressShopMonitoringDashboard`);
        console.log(result1.data,"Success testing");
      if(result1.data && result1.data.length > 0) {

        setoperatordashBoardData(result1.data);
      }
        // Avoid duplicate entries
        // setDashBoardData((prevData) => {
        //   const newData = dataArray.filter(
        //     (item) => !prevData.some((prevItem) => prevItem.id === item.id) // Use a unique identifier (e.g., `id`)
        //   );
        //   return [...prevData, ...newData];
        // });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  const fetchData = async () => {
    try {
      const batchSize = Number(BatchSizeConfig.BatchSize);
      let DowntimeArry =[]; 

      // Retrieve equipment data from localStorage
      const storedEquipGroupsData = localStorage.getItem("equipment");
      const equips = storedEquipGroupsData ? JSON.parse(storedEquipGroupsData) : [];
      if (!equips || equips.length === 0) return;

      const dataArray = [];
      for (let i = 0; i < Math.ceil(equips.length / batchSize); i++) {
        const startIndex = i * batchSize;
        const endIndex = (i + 1) * batchSize;
        const sendData = equips.slice(startIndex, endIndex);

        // Compress data using Pako
        const compressedData = Pako.gzip(
          JSON.stringify({ UserId: user._id, Equips: sendData })
        );

        try {
          // Send compressed data via API
          const result = await axios.put(
            `${api.api1}GetAndonViewDashboard`,
            compressedData,
            {
              headers: {
                "Content-Encoding": "gzip",
                "Content-Type": "application/json",
              },
            }
          );
          const result1 = await axios.put(`${api.api1}PressShopMonitoringDashboard`,  {UserId: user._id, Equips: sendData })
          if(result1.data && result1.data.length > 0) {
            result1.data.forEach((element) => {
              element.Event.forEach((item) => {
                const reason = item.DowntimeReason ?? 'Down';
                const duration = Math.round(parseFloat(item.Duration) / 60) ?? 0;
                const color = element.Color
                // Check if the DowntimeReason already exists in DowntimeArry
                const existingEntry = DowntimeArry.find(
                  (entry) => entry.DowntimeReason === reason
                );
            
                if (existingEntry) {
                  // If it exists, add the duration to the existing entry
                  existingEntry.Duration += duration;
                } else {
                  // If it doesn't exist, add a new entry
                  DowntimeArry.push({ DowntimeReason: reason, Duration: duration ,color});
                }
              });
            });
            
          }
          // Decompress received data
          const decompressedData = Pako.ungzip(result.data, { to: "string" });

          // Convert decompressed string to JSON
          const jsonData = JSON.parse(decompressedData);

          // Add new unique data only
          dataArray.push(...jsonData);
        } catch (err) {
          if (err.response?.data?.code === 901) {
            swal(`${err.response.data.message}`);
          } else {
            swal(`Error: ${err.message}`);
          }
        }
      }
      setdownTimedashBoardData(DowntimeArry);

      // Avoid duplicate entries
      setDashBoardData((prevData) => {
        const newData = dataArray.filter(
          (item) => !prevData.some((prevItem) => prevItem.id === item.id) // Use a unique identifier (e.g., `id`)
        );
        return [...prevData, ...newData];
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    // Fetch data immediately on first load
    fetchData1();
    fetchData2();
    // Set up 10-minute interval
    const interval = setInterval(() => {
      fetchData();
      fetchData2();
    }, 10 * 60 * 1000);
     // 10 minutes
    // const interval = setInterval(fetchData, 1 * 60 * 1000); // 1 minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div style={{ height: "100%" }}>
      {(
        <div id="cssportal-grid">
          <div
            id="div1"
            className="rounded box-glow"
            style={{ overflow: "hidden" }}
          >
            {dashBoardData.length > 0 ? <Apexbarchart
              jsonData={dashBoardData}
              title="Machine Performance (%)"
              header="Equipment"
              value="Performance"
              chartType="bar"
              height="100%"
              orientation="vertical"
            />: <div style={{ width: '100%', height: '100%' }}  className=' d-flex align-items-center justify-content-center'><p className='text-center bolder'>Loading Data ...</p></div>}
          </div>
          <div
            id="div2"
            className="rounded box-glow"
            style={{ overflow: "hidden" }}
          >
           {dashBoardData.length > 0 ? <Apexbarchart
              jsonData={dashBoardData}
              title="Machine Availability (%)"
              header="Equipment"
              value="Availability"
              chartType="bar"
              height="100%"
              orientation="vertical"
              barColor="#FF7F0E"
            />: <div style={{ width: '100%', height: '100%' }}  className=' d-flex align-items-center justify-content-center'><p className='text-center bolder'>Loading Data ...</p></div>}
          </div>
          <div
            id="div3"
            className="rounded box-glow"
            style={{ overflow: "hidden" }}
          >
           {dashBoardData.length > 0 ? <Apexbarchart
              jsonData={dashBoardData}
              title="Overall OEE (%)"
              header="Equipment"
              value="OEE"
              chartType="bar"
              height="100%"
              orientation="vertical"
              barColor="#2CA02C"
            />: <div style={{ width: '100%', height: '100%' }}  className=' d-flex align-items-center justify-content-center'><p className='text-center bolder'>Loading Data ...</p></div>}
          </div>
          <div
            id="div4"
            className="rounded box-glow"
            style={{ overflow: "hidden" }}
          >
            {console.log(downTimedashBoardData.map(one =>one.color),"colors in the map")}
            {downTimedashBoardData.length > 0 ?<Apexbarchart
              jsonData={downTimedashBoardData}
              title="Downtime (in mins)"
              header="DowntimeReason"
              value="Duration"
              chartType="bar"
              height="100%"
              orientation="vertical"
              // barColor="#D62728"
              barColors ={downTimedashBoardData.map(one =>one.color)}
            />: <div style={{ width: '100%', height: '100%' }}  className=' d-flex align-items-center justify-content-center'><p className='text-center bolder'>Loading Data ...</p></div>}
          </div>
          <div id="div6" className="rounded box-glow">
           {dashBoardData.length > 0 ? <Apexbarchart
              jsonData={dashBoardData}
              title="Production"
              header="Equipment"
              value="PartsProduced"
              chartType="bar"
              height="100%"
              orientation="vertical"
              barColor={"#9467BD"}
            />: <div style={{ width: '100%', height: '100%' }}  className=' d-flex align-items-center justify-content-center'><p className='text-center bolder'>Loading Data ...</p></div>}
          </div>
          <div
            id="div7"
            className="rounded box-glow"
            style={{ overflow: "hidden" }}
          >
            {operatordashBoardData.length > 0 ? <Apexbarchart
              jsonData={operatordashBoardData}
              title="Operator Performance (%)"
              header="Operator"
              value="Performance"
              chartType="bar"
              height="100%"
              barColor={"#9467BD"}
            />: <div style={{ width: '100%', height: '100%' }}  className=' d-flex align-items-center justify-content-center'><p className='text-center bolder'>Loading Data ...</p></div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default PressShopMonitoring;
